@use "../config/" as *;
.waf-showcase {
    @extend %pt-4;
    &-home {
        @extend %my-6;
    }
    .loadmore-wrap,
    .pagination-wrap,
    .reaction-section,
    // .icon-time-wrapper,
    .article-description {
        // .item-type-icon {
        @extend %d-none;
    }
    .layout-wrapper {
        @extend %px-4;
        @extend %my-0;
    }
    .article-meta {
        margin-bottom: 0;
    }
    .item-type-video {
        .article-thumbnail {
            position: relative;
        }
    }
    .article-list {
        .meta {
            max-width: max-content;
            font-size: 1rem;
            &.meta-category {
                @extend %secondary-600;
                @extend %absolute;
                @extend %font-10-pm;
                &:after {
                    content: unset;
                }
            }
        }
    }
    .first-list {
        @include listing-card(vertical);
        @extend %mb-4;
        .article-item {
            @extend %half-radius;
            @extend %pure-white-900-bg;
        }
        .article-content {
            padding: var(--space-4);
        }
        .img-box {
            border-radius: var(--half-radius) var(--half-radius) 0 0;
        }
        .article-title {
            @include truncate(var(--_line, 3), var(--_fontsize, 20), var(--_line-height, 26));
            @extend %font-20-peb;
            @extend %neutral-800;
            @extend %mt-5;
        }
        .article-meta {
            @extend %mt-5;
        }
        .meta {
            @extend %font-10-pm;
            @extend %neutral-500;
            @extend %capitalize;
            &.meta-category {
                top: var(--space-3);
                left: var(--space-4);
                overflow: visible;
                @extend %absolute;
            }
        }
    }
    .second-list {
        @include listing-card(horizontal);
        .article {
            &-item {
                @extend %pb-5;
                @extend %half-radius;
                &:not(:last-child) {
                    @extend %mb-5;
                    &::after {
                        content: "";
                        height: 0.1rem;
                        bottom: 0;
                        @extend %absolute;
                        @extend %w-100;
                        @extend %flex;
                        @extend %neutral-200-bg;
                    }
                }
            }
            &-content {
                position: relative;
                @extend %py-0;
                @extend %pl-4;
                @extend %pr-0;
            }
            &-thumbnail {
                width: 10.7rem;
            }
            &-title {
                @extend %neutral-800;
                @include truncate(var(--_line, 2), var(--_fontsize, 14), var(--_line-height, 20));
            }
            &-meta {
                align-items: center;
                @extend %w-100;
                @extend %mt-2;
            }
        }
        .meta {
            @extend %font-10-pm;
            @extend %neutral-500;
            &.meta-category {
                left: var(--space-4);
            }
        }
        .item-type-video {
            .timestamp {
                top: unset;
                bottom: var(--space-2);
                left: -10rem;
                height: 2rem;
                font-size: 1rem;
                padding: 0 var(--space-1);
            }
        }
    }
    .waf-ads {
        @extend %px-4;
        @extend %mb-4;
        img {
            @extend %half-radius;
        }
    }
    &.waf-secondary-showcase {
        @extend %pure-white-900-bg;
        @extend %py-8;
        :where(.img-box) {
            @extend %pure-white-400-bg-6;
        }
        .layout-wrapper {
            display: grid;
            gap: var(--space-4);
            @extend %w-100;
        }
        .waf-head {
            @extend %d-block;
        }
        .article {
            &-title {
                --_line: 2;
                --_fontsize: 14;
                --_line-height: 20;
                @extend %neutral-800;
            }
            &-item {
                @extend %transparent-bg;
            }
            &-thumbnail {
                border-radius: var(--half-radius);
                overflow: hidden;
            }
            &-content {
                position: relative;
            }
            &-list {
                @extend %neutral-50-bg;
                @extend %p-4;
                @extend %mb-0;
                .meta {
                    &.meta-category {
                        top: 0;
                        left: 1.5rem;
                    }
                }
            }
        }
        .first-list,
        .third-list {
            @include listing-card(horizontal);
            @extend %flex-column;
            @extend %gap-5;
            .article {
                &-thumbnail {
                    width: 10.7rem;
                }
                &-content {
                    padding: 0 0 0 var(--space-4);
                    width: calc(100% - 10.7rem);
                    position: relative;
                }
                &-meta {
                    @extend %mt-2;
                }
            }
        }
        .second-list {
            @extend %p-4;
            .meta {
                &.meta-category {
                    top: 1.5rem;
                    left: 0;
                }
            }
            .article {
                &-title {
                    --_fontsize: 16;
                }
                &-item {
                    @extend %py-0;
                }
                &-thumbnail,
                &-content {
                    @extend %w-100;
                }
                &-content {
                    row-gap: 1rem;
                    @extend %px-0;
                    @extend %pt-6;
                }
                &-wrap {
                    flex-direction: column;
                }
            }
            .item-type-video {
                .timestamp {
                    top: -4rem;
                    left: 1.6rem;
                }
            }
        }
        .loadmore-wrap,
        .pagination-wrap {
            @extend %d-none;
        }
    }
}
@include mq(col-md) {
    .waf-showcase {
        padding-top: var(--space-10);
        .layout-wrapper {
            padding-inline: 0;
        }
        .first-list {
            display: block;
        }
        .second-list {
            grid-template-columns: 1fr;
            gap: 0;
        }
        .waf-ads {
            padding-inline: 0;
            height: 100%;
            margin: 0;
        }
    }
}
@include mq(col-lg) {
    .waf-showcase {
        display: flex;
        .layout-wrapper {
            display: flex;
            width: calc(100% - var(--ads-width));
            gap: var(--space-4);
            margin-inline: 0;
            padding-right: var(--space-4);
            & ~ section,
            & ~ div {
                width: var(--ads-width);
            }
        }
        .first-list {
            width: calc(60% - var(--space-1));
            --_line: 2;
            margin-bottom: 0;
            .article-title {
                margin-top: 1.5rem;
            }
            .article-item {
                height: 100%;
            }
        }
        .second-list {
            width: calc(40% - var(--space-1));
            gap: 0;
            .article-item {
                margin-bottom: 1.6rem;
                padding-bottom: 1.6rem;
                &:last-child {
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
            }
            .article-content {
                padding-bottom: 0;
                margin-bottom: var(--space-4);
                width: calc(100% - 10.7rem);
                margin-bottom: 0;
            }
        }
        &.waf-secondary-showcase {
            padding-block: var(--space-14);
            .layout-wrapper {
                margin-inline: auto;
                grid-template-columns: repeat(3, 1fr);
            }
            .waf-head {
                grid-area: 1 / 1 / 2 / 4;
            }
            .first-list,
            .second-list,
            .third-list {
                width: 100%;
            }
            .second-list {
                margin-bottom: 0;
                .article {
                    &-title {
                        --_fontsize: 20;
                        --_line-height: 28;
                    }
                    &-list {
                        gap: 0;
                    }
                }
            }
        }
    }
}