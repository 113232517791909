@use "../config/" as *;
.waf-listing.waf-home-video-listing {
    padding: var(--space-10) 0 var(--space-8);
    @include bg(primary-300);
    @include mq(col-md) {
        padding-block: var(--space-12);
        .layout-wrapper {
            padding: 0;
        }
    }
    .article {
        &-list {
            overflow: auto;
            @extend %mx-4-neg;
            @extend %px-4;
            @include mq(col-md) {
                padding-inline: 0;
                margin-inline: 0;
                overflow: unset;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                > * {
                    width: 100%;
                }
            }
            @include mq(col-lg) {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
            }
            > * {
                width: 90%;
            }
            .img-box {
                border-radius: var(--half-radius) var(--half-radius) 0 0;
            }
        }
        &-content {
            width: 95%;
            border-radius: 0 var(--half-radius) var(--half-radius) 0;
            @extend %mt-4-neg;
            @extend %neutral-50-bg;
            @extend %px-4;
        }
        &-meta {
            @extend %mt-3;
            .meta-category {
                left: var(--space-4)
            }
            .meta-author {
                font-size: 1rem;
            }
        }
    }
}