@use "../config/" as *;
.site-header {
    @extend %w-100;
}
.header {
    &-wrap {
        .menu-wrapper {
            --_logo-width: 10.3rem;
            --_logo-height: 2.26rem;
            position: fixed;
            bottom: 0;
            height: var(--header-height);
            z-index: var(--z-header);
            @extend %w-100;
            @include hamburger();
            box-shadow: 0rem -.4rem .4rem 0rem clr(neutral-900, 1);
            @extend %pure-white-900-bg;
            @extend %flex-sb-c;
            @extend %h-100;
        }
        .btn-hamburger {
            &[aria-expanded=true] ~ .menu-wrap {
                right: 0;
            }
        }
    }
    &-top {
        flex: 1;
        @extend %px-3;
        @extend %flex-n-c;
        @include mq(col-lg) {
            flex: unset;
        }
        .site-logo {
            @extend %mr-auto;
            .logo-text {
                @extend %font-0;
            }
            .logo {
                width: var(--_logo-width);
                display: block;
                height: var(--_logo-height);
                margin-bottom: env(safe-area-inset-bottom);
                transition: 300ms height, 300ms width;
                @include background(null, "logo-blue.png", center/contain no-repeat);
            }
        }
        .user-module {
            isolation: isolate;
        }
        .login-section {
            .btn-text {
                @extend %font-12-pm;
                @extend %d-none;
                @extend %uppercase;
                @extend %secondary-900;
                @include mq(col-lg) {
                    display: block;
                }
            }
            .btn-menu {
                @extend %p-3;
                @extend %flex-n-c;
                @extend %gap-2;
                &[aria-expanded=true] {
                    & ~ .list-wrap {
                        opacity: 1;
                        pointer-events: all;
                        .btn-site {
                            height: 3rem;
                        }
                    }
                    img {
                        display: none;
                    }
                    .btn-text {
                        @extend %font-0;
                    }
                    &.btn-image {
                        &::before {
                            width: 2.8rem;
                            height: 2.8rem;
                            @extend %circle-radius;
                            @extend %flex-c-c;
                            @extend %secondary-900-bg;
                        }
                    }
                    &::before {
                        @extend %pure-white-900;
                        @include icon(close, 12);
                    }
                }
            }
            img {
                flex-shrink: 0;
                width: 2.8rem;
                @include mq(col-lg) {
                    width: 2rem;
                }
            }
            button.btn-login {
                width: 2.8rem;
                height: 2.8rem;
                gap: 0;
                @extend %p-0;
                @extend %flex-c-c;
                @extend %secondary-900-bg;
                @extend %circle-radius;
                &.btn-image {
                    background: transparent;
                }
                .btn-text {
                    display: flex;
                    @extend %pure-white-900;
                }
            }
            .list-wrap {
                position: absolute;
                bottom: 100%;
                right: 0;
                isolation: isolate;
                box-shadow: .1rem .1rem .5rem -0.2rem clr(secondary-800, 5);
                opacity: 0;
                pointer-events: none;
                transition: 300ms opacity;
                border-radius: var(--half-radius) var(--half-radius) 0 0;
                @extend %neutral-800-bg-9;
                @extend %p-4;
                @extend %gap-3;
                @extend %flex;
                @extend %w-100;
                .btn-text {
                    display: block;
                    @extend %pure-white-900;
                }
                .btn-site {
                    height: 0rem;
                    overflow: hidden;
                    transition: 300ms height;
                    flex: 1;
                    border: .1rem solid var(--pure-white-900);
                    @extend %half-radius;
                    @extend %flex-c-c;
                    @extend %w-100;
                    @extend %text-center;
                    @extend %px-3;
                    &.active {
                        @extend %primary-300-bg;
                        border: .1rem solid var(--primary-300);
                        .btn-text {
                            @extend %secondary-900;
                        }
                    }
                }
            }
        }
    }
    &-bottom {
        .menu-wrap {
            position: fixed;
            top: 0;
            right: -100%;
            height: calc(100vh - var(--header-height));
            height: calc(100dvh - var(--header-height));
            max-width: 31rem;
            transition: 300ms right ease-in-out;
            z-index: var(--z-patterns);
            @extend %primary-50-bg;
            @extend %w-100;
            ul {
                list-style-type: none;
                padding: 0;
            }
            .site-nav {
                height: calc(100% - var(--social-height, 6rem));
                overflow-y: auto;
                @include mq(col-md) {
                    height: auto;
                    display: flex;
                    align-items: center;
                }
            }
            .nav {
                &-list {
                    @extend %px-4;
                    @extend %pt-6;
                }
                &-item-active {
                    @extend %relative;
                    &::after {
                        content: "";
                        height: 0.1rem;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        background-image: linear-gradient(to right, #160429, transparent);
                        @extend %w-100;
                        @include mq(col-md) {
                            display: none;
                        }
                    }
                    > .nav-anchor {
                        line-height: 1;
                        @extend %font-16-pb;
                        @include mq(col-md) {
                            font-size: 1.2rem
                        }
                    }
                }
                &-anchor {
                    line-height: 1;
                    @extend %font-12-pm;
                    @extend %neutral-800;
                    @extend %d-block;
                    @extend %py-3;
                    @extend %uppercase;
                    @extend %w-100;
                    @extend %text-left;
                }
                &-item-submenu {
                    > .nav-anchor {
                        @extend %flex-sb-c;
                        @extend %gap-2;
                        &::after {
                            transition: 0.3s rotate;
                            @include icon(p-arrow-down, 12);
                        }
                        &[aria-expanded=true] {
                            &::after {
                                rotate: 180deg;
                            }
                        }
                    }
                }
            }
            .submenu,
            .submenu-subnav {
                max-height: 0;
                transition: max-height 0.3s ease-in-out;
                @extend %hidden;
            }
            .nav-anchor[aria-expanded="true"] + .submenu,
            .nav-anchor[aria-expanded="true"] + .submenu-subnav {
                max-height: 50rem;
            }
            .submenu {
                &-item {
                    .nav-anchor {
                        white-space: nowrap;
                        @extend %w-100;
                    }
                }
                &-item:last-child {
                    .subnav-item:last-child {
                        border-bottom: 0
                    }
                }
            }
            .subnav {
                > .nav-anchor {
                    @extend %flex-sb-c;
                    @extend %gap-2;
                    &::after {
                        transition: 0.3s rotate;
                        @include icon(add, 12);
                    }
                    &[aria-expanded=true] {
                        &::after {
                            rotate: 180deg;
                            @include icon(minus, 12);
                        }
                    }
                }
                &-item {
                    .nav-anchor {
                        @extend %neutral-600;
                        @extend %font-12-pr;
                    }
                    &:last-child {
                        border-bottom: .1rem solid var(--neutral-600);
                    }
                }
            }
        }
        .promo-link-label {
            @extend %d-none;
        }
        .promo {
            &-anchor {
                width: 2.6rem;
                height: 2.6rem;
                transition: 300ms background-color;
                @extend %d-block;
                @extend %circle-radius;
                @extend %hidden;
                @extend %primary-200-bg;
                @extend %flex-c-c;
                @extend %secondary-800;
                &:hover {
                    @extend %primary-600-bg;
                }
                @each $key, $value in $social {
                    &-#{$key}::before {
                        @include icon($key);
                    }
                }
                .promo-text {
                    @extend %font-0;
                }
            }
            &-list {
                @extend %flex-c-n;
                @extend %gap-4;
                &-wrap {
                    box-shadow: 0rem -.4rem .4rem 0rem clr(neutral-900, 1);
                    height: var(--social-height);
                    @extend %py-3;
                    @extend %primary-100-bg;
                    @include mq(col-md) {
                        box-shadow: unset;
                    }
                }
            }
        }
    }
}
.redirection-module {
    .redirection {
        &-list {
            list-style: none;
            @extend %flex;
            @extend %gap-2;
        }
        &-link {
            display: none;
            @extend %p-4;
            @extend %relative;
            @include mq(col-lg) {
                display: flex;
            }
            &-submenu {
                @extend %relative;
                & > .nav-anchor {
                    @extend %flex-n-c;
                    @extend %gap-2;
                    &::after {
                        transition: rotate 300ms;
                        color: inherit;
                        @include icon(chevron-down);
                    }
                    &[aria-expanded=true] {
                        &::after {
                            rotate: 180deg
                        }
                        & ~ .submenu {
                            &::before {
                                display: block;
                            }
                            .nav-anchor {
                                height: 3rem;
                            }
                        }
                    }
                }
            }
            .nav-anchor {
                @extend %flex-c-c;
                @extend %gap-2;
                @extend %font-12-pb;
                @extend %secondary-800;
                @extend %uppercase;
            }
            &-store {
                > .nav-anchor::before {
                    font-weight: 400;
                    @include icon(store);
                    @extend %mr-1;
                }
                .nav-text {
                    &[data-basket] {
                        &::before {
                            content: attr(data-basket);
                            font-size: .9rem;
                            top: 0.6rem;
                            right: 6rem;
                            width: 1.4rem;
                            height: 1.4rem;
                            @include flex-config(flex, null, center, center);
                            @extend %absolute;
                            @extend %pure-white-900;
                            @extend %circle-radius;
                            @extend %error-800-bg;
                        }
                    }
                    &[data-basket="0"] {
                        &::before {
                            content: unset;
                        }
                    }
                }
            }
            &-game-zone {
                > .nav-anchor::before {
                    font-weight: 400;
                    @include icon(game-zone);
                    @extend %mr-1;
                }
            }
            &-patreon {
                > .nav-anchor::before {
                    font-weight: 400;
                    @include icon(patreon);
                    @extend %mr-1;
                }
            }
            &-login {
                display: block;
                > .nav-anchor::before {
                    font-weight: 400;
                    @include icon(user-1, 26);
                    @extend %mr-1;
                    @include mq(col-lg) {
                        font-size: 1.4rem
                    }
                }
                .nav-text {
                    display: none;
                    @include mq(col-lg) {
                        display: block;
                    }
                }
            }
            @include mq(col-lg) {
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: .1rem;
                    height: 40%;
                    background-color: var(--secondary-800);
                }
                // &:not(:last-child)::after {
                //     content: "";
                //     position: absolute;
                //     right: 0;
                //     top: 50%;
                //     transform: translateY(-50%);
                //     width: .1rem;
                //     height: 40%;
                //     background-color: var(--secondary-800);
                // }
            }
        }
    }
    .submenu {
        position: absolute;
        top: 100%;
        width: max-content;
        list-style: none;
        z-index: var(--z-header);
        @extend %neutral-50-bg;
        @extend %full-radius;
        @extend %relative;
        @extend %p-0;
        &::before {
            content: "";
            display: none;
            width: 1rem;
            height: 1rem;
            position: absolute;
            top: -.5rem;
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
            @extend %neutral-50-bg;
        }
        .nav-anchor {
            min-width: 10rem;
            height: 0rem;
            overflow: hidden;
            padding-block: 0;
            transition: 300ms height;
            @extend %flex-column-c-c;
            @extend %px-2;
            @extend %text-center;
        }
    }
}
.secondary-menu-active {
    .main-wrap {
        // padding-top: var(--secondary-menu-height);
        @include mq(col-md) {
            padding-top: calc(var(--header-height) + var(--secondary-menu-height))
        }
    }
    .secondary-menu {
        display: block;
        position: static;
        @include mq(col-md) {
            display: flex;
        }
    }
}
.secondary-menu {
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 0rem .4rem 1rem 0px clr(neutral-900, 1);
    z-index: var(--z-secondary-navbar);
    height: var(--secondary-menu-height);
    @extend %d-none;
    @extend %neutral-50-bg;
    @extend %w-100;
    &-head {
        @extend %primary-300-bg;
        @extend %p-3;
    }
    &-title {
        @extend %font-12-pb;
    }
    &-body {
        ul {
            @extend %p-0;
        }
        .nav {
            &-list {
                list-style: none;
                overflow-x: auto;
                @extend %flex;
                @extend %gap-2;
            }
            &-item {
                width: max-content;
                white-space: nowrap;
                &-submenu {
                    @extend %relative;
                    & > .nav-anchor {
                        @extend %flex-n-c;
                        @extend %gap-2;
                        &::after {
                            transition: rotate 300ms;
                            color: inherit;
                            @include icon(chevron-down);
                        }
                        &[aria-expanded=true] {
                            &::after {
                                rotate: 180deg
                            }
                            & ~ .submenu {
                                &::before {
                                    display: block;
                                }
                                .nav-anchor {
                                    height: 4rem;
                                }
                            }
                        }
                    }
                }
                &-active {
                    .nav-anchor {
                        font-weight: 700;
                        @extend %neutral-900;
                    }
                }
            }
            &-anchor {
                transition: 300ms height;
                @extend %flex-c-c;
                @extend %font-12;
                @extend %p-3;
                @extend %h-100;
                @extend %neutral-800;
            }
        }
        .submenu {
            position: absolute;
            top: calc(100% + 1rem);
            width: max-content;
            list-style: none;
            @extend %neutral-50-bg;
            @extend %full-radius;
            @extend %relative;
            &::before {
                content: "";
                display: none;
                width: 1rem;
                height: 1rem;
                position: absolute;
                top: -.5rem;
                left: 50%;
                transform: translateX(-50%) rotate(45deg);
                @extend %neutral-50-bg;
            }
            .nav-anchor {
                height: 0rem;
                overflow: hidden;
                padding-block: 0;
                @extend %text-center;
            }
        }
    }
}
.sticky {
    .waf-showcase {
        &.widget-layout-04 {
            height: 0;
            opacity: 0;
        }
    }
}
.region-section {
    .btn-region {
        @extend %gap-2;
        @extend %mr-3;
        @extend %flex-c-c;
        .btn-text {
            @extend %d-none;
            @extend %font-12-pb;
            @extend %uppercase;
            @extend %secondary-800;
            &.btn-text-edition {
                @extend %flex;
                @extend %font-12-pm;
            }
        }
        &::before {
            @extend %secondary-800;
            @include icon(region, 25);
        }
        &[aria-expanded=true] {
            & ~ .list-wrap {
                opacity: 1;
                pointer-events: all;
                .btn-site {
                    height: 3rem;
                }
            }
            &::before {
                @include icon(close, 25);
            }
        }
    }
    .list-wrap {
        isolation: isolate;
        opacity: 0;
        pointer-events: none;
        transition: 300ms opacity;
        border-radius: var(--half-radius) var(--half-radius) 0 0;
        flex-wrap: wrap;
        z-index: var(--z-region-box);
        @extend %neutral-50-bg;
        @extend %p-4;
        @extend %gap-3;
        @extend %flex;
        @extend %w-100;
        @include position(null, 0, 100%, null);
        .btn-text {
            @extend %secondary-800;
            @extend %font-14-pm;
        }
        .title {
            border-bottom: .1rem solid var(--neutral-100);
            @extend %w-100;
            @extend %pb-3;
            @extend %secondary-800;
            @extend %capitalize;
            @extend %font-16-pb;
        }
        .btn-site {
            height: 0rem;
            overflow: hidden;
            transition: 300ms height;
            @extend %half-radius;
            @extend %flex-n-c;
            @extend %w-100;
            @extend %text-left;
            &.active {
                .btn-text {
                    font-weight: 700;
                }
            }
        }
    }
}
.site-search-section {
    max-width: 31rem;
    width: 100%;
    @include position(0, -100%, null, null, fixed);
    transition: 300ms right ease-in-out;
    @extend %primary-50-bg;
    &::after {
        right: 0;
        width: .1rem;
        @extend %h-40;
        @extend %secondary-800-bg;
        @extend %pos-y-center;
    }
    .site-search {
        @extend %w-100;
    }
    .btn-search-toggle {
        border: .1rem solid var(--neutral-100);
        width: calc(100% - 3.2rem);
        min-height: 3.3rem;
        cursor: pointer;
        z-index: var(--z-default);
        @extend %flex-sb-c;
        @extend %m-6-4;
        @extend %px-3;
        @extend %half-radius;
        @extend %text-left;
        @extend %pure-white-900-bg;
        &::after {
            @include icon(search);
        }
        .btn-text {
            @extend %capitalize;
            @extend %neutral-800;
            @extend %font-12-pm;
        }
        &[aria-expanded=true] {
            width: 2rem;
            height: 2rem;
            min-height: 2rem;
            border-radius: 0;
            border: 0;
            @include position(var(--space-3), var(--space-3), null, null);
            @extend %transparent-bg;
            @extend %m-0;
            @extend %p-0;
            @extend %flex-c-c;
            &::before {
                @include icon(close);
                @extend %pure-white-900;
            }
            &::after {
                content: unset;
            }
            .btn-text {
                @extend %font-0;
            }
            & ~ .site-search-popup {
                @include dropdown(open);
            }
        }
    }
    .site-search-popup {
        background: linear-gradient(92.5deg, var(--secondary-800) -0.01%, var(--secondary-1000) 133.57%);
        height: calc(100% - var(--header-height));
        opacity: .96;
        padding: var(--space-10) var(--space-4) var(--space-4);
        @include backdropBlur(2rem);
        @include dropdown(close);
        @include position(0, null, null, 0, fixed);
        @extend %w-100;
        .btn-close {
            @include dropdown(close);
        }
    }
    .site-search-form {
        @extend %relative;
        @extend %mb-4;
    }
    .site-search-input {
        min-height: 5.2rem;
        border: 0;
        padding-right: 12rem;
        @extend %neutral-600;
        @extend %font-12-pm;
        @extend %w-100;
        @extend %p-4;
        @extend %half-radius;
        @extend %pure-white-900-bg;
    }
    input[type="search"]::-webkit-search-cancel-button {
        -webkit-appearance: none;
        appearance: none;
    }
    .btn-search {
        @include position(var(--space-3), var(--space-3), null, null);
        @include btn-animation;
        &::after {
            width: 0;
            font-size: 0;
        }
        &:hover {
            &::after {
                width: 1.4rem;
                font-size: 1.4rem;
            }
        }
        .btn-text {
            @extend %uppercase;
        }
    }
    .search-tabs-title {
        @extend %pure-white-900;
        @extend %font-12-pb;
    }
    .search-tabs-list {
        flex-wrap: wrap;
        list-style: none;
        @extend %my-4;
        @extend %gap-4;
        @extend %flex;
        @extend %pl-0;
    }
    .search-tabs-item {
        cursor: pointer;
        min-height: 3rem;
        @extend %pure-white-900-bg-2;
        @extend %half-radius;
    }
    .search-tabs-link {
        @extend %px-2;
        @extend %gap-2;
        @extend %flex-c-c;
        @extend %w-100;
        @extend %h-100;
        @extend %pure-white-900;
        @extend %font-12-pm;
        &::after {
            @include icon(drop-arrow, 14);
            @extend %pure-white-900;
        }
    }
}
.search-active {
    .site-search-section {
        right: 0;
    }
    .header-bottom {
        .menu-wrap {
            top: 5rem;
            height: calc(100vh - var(--header-height) - 5rem);
            height: calc(100dvh - var(--header-height) - 5rem);
        }
    }
}
@include mq(col-lg) {
    .header {
        &-wrap {
            .menu-wrapper {
                --_logo-width: 12.9rem;
                --_logo-height: 2.8rem;
                bottom: unset;
                top: 0;
                background-color: var(--primary-300);
                width: 100%;
                flex-direction: column;
                .btn-hamburger {
                    display: none;
                }
            }
            .desktop-hidden {
                display: none;
            }
        }
        &-top {
            width: 100%;
            height: var(--header-top-height);
            display: flex;
            align-items: center;
            .user-module {
                isolation: unset;
            }
            .login-section {
                button.btn-login {
                    margin-inline: var(--space-3);
                }
                .btn-menu[aria-expanded=true] {
                    ~ .list-wrap .btn-site {
                        min-height: 3rem;
                    }
                }
                .list-wrap {
                    flex-direction: column;
                    z-index: var(--z-login-wrap);
                    width: max-content;
                    top: var(--header-top-height);
                    bottom: unset;
                    border-radius: 0 0 var(--half-radius) var(--half-radius);
                }
            }
        }
        &-bottom {
            width: 100%;
            height: var(--header-bottom-height);
            margin: 0;
            background-color: var(--primary-100);
            .menu-wrap {
                width: 100%;
                position: unset;
                display: flex;
                height: 100%;
                max-width: unset;
                background-color: transparent;
                .site-nav {
                    overflow-y: unset;
                }
                .nav {
                    &-list {
                        display: flex;
                        gap: var(--space-6);
                        padding-top: 0;
                    }
                    &-item {
                        position: relative;
                        &-submenu > .nav-anchor[aria-expanded=true] ~ .submenu {
                            &::before {
                                display: block;
                            }
                        }
                    }
                    &-anchor {
                        width: max-content;
                    }
                }
                .submenu {
                    position: absolute;
                    top: calc(100% + 1rem);
                    left: 0;
                    background-color: var(--pure-white-900);
                    padding-inline: var(--space-3);
                    border-radius: 0 0 var(--full-radius) var(--full-radius);
                    box-shadow: 0px 0px 10px 0px clr(neutral-900, 1);
                    &::before {
                        content: "";
                        display: none;
                        width: 1rem;
                        height: 1rem;
                        position: absolute;
                        top: -.5rem;
                        left: 2rem;
                        transform: rotate(45deg);
                        background-color: var(--pure-white-900);
                        .nav-anchor {
                            text-transform: capitalize;
                        }
                    }
                }
            }
            .promo-list-wrap {
                margin-left: auto;
                padding-block: 0;
                height: 100%;
                display: flex;
                align-items: center;
                padding-inline: var(--space-5);
                position: relative;
                isolation: isolate;
                background-color: transparent;
                &::after {
                    content: "";
                    width: 130%;
                    height: 100%;
                    z-index: var(--z-patterns);
                    position: absolute;
                    top: 0;
                    left: 0;
                    transform: skewX(-10deg);
                    box-shadow: -4px 0px 10px 0px clr(neutral-900, 1);
                    background-color: var(--primary-100);
                }
            }
            .social-menu {
                margin-left: auto;
            }
        }
    }
    .sticky {
        .header {
            &-wrap {
                --_logo-width: 9.1rem;
                --_logo-height: 2rem;
                .menu-wrapper {
                    flex-direction: row;
                }
            }
            &-top,
            &-bottom,
            &-wrap {
                height: var(--header-top-height);
                transition: height ease-in-out 700ms;
            }
            &-bottom {
                background-color: transparent;
                flex: 1;
            }
            &-top {
                width: 16rem;
                overflow: hidden;
                flex-shrink: 0;
                transition: width ease-in-out 700ms;
                .user-module,
                .redirection-list,
                .region-section,
                .site-search-section {
                    // display: none;
                    opacity: 0;
                }
            }
        }
    }
    .secondary-menu {
        top: var(--header-height);
        align-items: center;
        &-head {
            background-color: transparent;
            position: relative;
            &::after {
                content: "";
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                height: 50%;
                width: .1rem;
                background-color: clr(neutral-100);
            }
        }
        &-body {
            .nav {
                &-list {
                    overflow-x: unset;
                }
                &-item {
                    white-space: unset;
                }
            }
        }
    }
    .region-section {
        position: relative;
        .btn-region {
            margin-right: 0;
            .btn-text {
                display: flex;
                &.btn-text-edition {
                    display: none;
                }
            }
            &::before {
                font-size: 1.4rem;
            }
            &::after {
                transition: 300ms rotate;
                @include icon(chevron-down, 14);
            }
            &[aria-expanded=true] {
                & ~ .list-wrap {
                    &::before {
                        content: '';
                        width: 1rem;
                        height: 1rem;
                        position: absolute;
                        top: -0.5rem;
                        left: 50%;
                        transform: translateX(-50%) rotate(45deg);
                        background: var(--pure-white-900);
                    }
                }
                &::before {
                    @include icon(region, 14);
                }
                &::after {
                    rotate: 180deg;
                }
            }
        }
        .list-wrap {
            top: calc(100% + 2.5rem);
            bottom: unset;
            flex-direction: column;
            right: unset;
            width: 13rem;
            background: var(--pure-white-900);
            border-radius: 0 0 var(--half-radius) var(--half-radius);
            box-shadow: 0 0 1rem 0 clr(neutral-900, 2);
            .title {
                display: none;
            }
            .btn-text {
                font-size: 1.2rem;
            }
        }
    }
    .site-search-section {
        position: relative;
        width: auto;
        background: transparent;
        top: unset;
        right: unset;
        margin-right: var(--space-4);
        &::after {
            content: '';
        }
        .btn-search-toggle {
            width: auto;
            min-height: auto;
            margin: 0;
            border: 0;
            background: transparent;
            padding: var(--space-4);
            @include flex-config(flex, null, center, center);
            .btn-text {
                font-size: 0;
            }
            &[aria-expanded=true] {
                position: unset;
                width: auto;
                height: auto;
                min-height: auto;
                padding: var(--space-4);
                &::before {
                    color: var(--neutral-900);
                    @include icon(close, 12);
                }
            }
        }
        .site-search-popup {
            top: var(--header-height);
            padding: var(--space-16) 0 var(--space-4) 0;
        }
        .site-search-wrapper {
            width: 60%;
            margin-inline: auto;
        }
        .site-search-input {
            padding-inline: var(--space-10) 11rem;
        }
        .site-search-form {
            margin-bottom: var(--space-6);
            &::before {
                @include icon(search, 16);
                @include position(1.8rem, null, null, var(--space-4));
            }
        }
    }
    .search-active {
        .header-bottom {
            .menu-wrap {
                top: 5rem;
                height: 100%;
            }
        }
    }
    .secondary-menu-active {
        .secondary-menu {
            position: fixed;
        }
    }
}