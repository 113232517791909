@forward "./../swiper/swiper-bundle";
@use "../config/" as *;
@each $class-id, $class-val in $betting-colors {
    .waf-betting-site {
        .#{$class-id}-site {
            @each $class-key, $betting-color in $class-val {
                --betting-#{$class-key}-color: #{$betting-color};
            }
        }
    }
}
@each $class-id, $class-val in $betting-colors {
    .betting-site-item {
        &.#{$class-id}-site {
            @each $class-key, $betting-color in $class-val {
                --betting-#{$class-key}-color: #{$betting-color};
            }
        }
    }
}
.waf-betting-site {
    container: betting-site/inline-size;
    @extend %p-4;
    .waf-head {
        .title {
            @extend %secondary-800;
            @extend %my-5;
            @extend %uppercase;
            @extend %font-16-pb;
        }
    }
    .star-rating {
        flex-shrink: 0;
        @extend %gap-2;
        @extend %flex-sb-c;
        .text {
            @extend %pure-white-900;
            @extend %font-12-pm;
        }
        .icon {
            @extend %font-0;
            &::before {
                @extend %primary-300;
                @include icon(star);
            }
        }
    }
    .betting-site {
        &-list {
            display: unset;
        }
        &-wrapper {
            position: static;
            overflow: visible;
        }
        &-item {
            &:not(:last-child) {
                @extend %mb-4;
            }
            .item {
                &-wrap {
                    flex-wrap: wrap;
                    height: 12rem;
                    background: var(--betting-primary-color, var(--neutral-800));
                    overflow: hidden;
                    @extend %relative;
                    @extend %half-radius;
                    @extend %p-4;
                    @extend %flex-sb-n;
                }
                &-button {
                    width: 7rem;
                    @extend %pl-2;
                    @extend %gap-4;
                    @extend %flex-column-c-n;
                    .btn-wrap {
                        @extend %gap-4;
                        @extend %flex-fe-n;
                    }
                    .btn-site {
                        flex-basis: calc(50% - var(--space-2));
                        height: 2.4rem;
                        @extend %gap-3;
                        @extend %relative;
                        @extend %flex-c-c;
                        @extend %uppercase;
                        @extend %font-10-pb;
                        @extend %secondary-800;
                        @extend %half-radius;
                        @extend %primary-300-bg;
                        &:after {
                            // @include icon(drop-arrow);
                        }
                        &.btn-read {
                            @extend %d-none;
                        }
                        &.btn-visit {
                            flex-grow: 1;
                            @extend %font-8-pb;
                            @extend %pure-white-900;
                            @extend %success-800-bg;
                        }
                    }
                }
                &-description {
                    width: calc(100% - 8.5rem);
                    @extend %pr-2;
                    @extend %flex-n-c;
                    @extend %gap-4;
                    .description {
                        @extend %font-10-pb;
                        @extend %pure-white-900;
                    }
                    > .description {
                        @include truncate-text(2);
                    }
                }
                &-number {
                    width: 4.5rem;
                    height: 4.5rem;
                    background: var(--betting-secondary-color, var(--neutral-700));
                    @extend %mr-3;
                    @extend %flex-c-c;
                    @extend %half-radius;
                    .number {
                        @extend %pure-white-900;
                        @extend %uppercase;
                        @extend %font-20-peb;
                    }
                }
                &-content {
                    width: calc(100% - 5.7rem);
                    height: 4.5rem;
                    .item-content-wrap {
                        background: var(--betting-secondary-color, var(--neutral-700));
                        @extend %h-100;
                        @extend %gap-2;
                        @extend %p-2;
                        @extend %flex-sb-c;
                        @extend %half-radius;
                    }
                    .logo {
                        width: auto;
                        height: 2.5rem;
                        img {
                            object-fit: contain;
                            object-position: left;
                            @extend %h-100;
                            @extend %w-100;
                        }
                    }
                }
            }
            &.parimatch-site {
                .item-description {
                    > .description {
                        color: var(--neutral-800);
                    }
                }
                .tooltip {
                    .info-icon {
                        &::before {
                            color: var(--neutral-800);
                        }
                    }
                }
            }
        }
    }
    .tooltip {
        // @extend %d-none;
        @extend %flex-c-c;
        &-data {
            width: 100%;
            height: 100%;
            border: .01rem solid var(--neutral-200);
            z-index: var(--z-tooltip-data);
            overflow-y: auto;
            @include position(null, 0, -100%, null);
            @extend %transition;
            @extend %half-radius;
            @extend %neutral-50-bg;
            @extend %p-2;
            @extend %flex-column;
        }
        .offers {
            @extend %mb-4;
            .title {
                @extend %uppercase;
                @extend %text-left;
                @extend %neutral-800;
                @extend %font-10-pb;
            }
            .text {
                @extend %text-left;
                @extend %neutral-800;
                @extend %font-9-pb;
            }
            a {
                color: var(--success-800);
                text-decoration: underline;
                @extend %uppercase;
            }
        }
        .description {
            .title {
                @extend %capitalize;
                @extend %text-left;
                @extend %neutral-800;
                @extend %font-9-pb;
            }
            .text {
                @extend %text-left;
                @extend %neutral-800;
                @extend %font-8-pr;
            }
        }
        .info-icon {
            width: 1.5rem;
            height: 1.5rem;
            cursor: pointer;
            @extend %flex-c-c;
            @extend %font-0;
            &::before {
                @extend %pure-white-900;
                @include icon(info);
            }
            &[aria-expanded=true] {
                & ~ .tooltip-data {
                    bottom: 0;
                }
                &::after {
                    z-index: var(--z-tooltip-data-icon);
                    @extend %circle-radius;
                    @extend %p-1;
                    @extend %pure-white-900-bg;
                    @extend %neutral-800;
                    @include icon(close, 10);
                    @include position(var(--space-2), var(--space-2), null, null);
                }
            }
        }
    }
    .swiper-button {
        @extend %d-none;
        &-prev {
            left: auto;
            right: 3rem;
        }
        &-next {
            right: 0;
        }
    }
    .tap-more {
        border-radius: 0 0 var(--half-radius) var(--half-radius);
        @extend %p-2-4;
        @extend %w-100;
        @extend %flex;
        @extend %pure-white-900-bg;
        &-text {
            text-decoration: underline;
            @extend %neutral-600;
            @extend %font-12-pm;
        }
    }
    &.uk-betting-site {
        .betting-site-item {
            .item-wrap {
                border-radius: var(--half-radius) var(--half-radius) 0 0;
            }
            &.betafairexchange-site,&.betway-site,&.betfair-site {
                .item-number .number {
                    @extend %neutral-800;
                }
                .star-rating .text {
                    @extend %neutral-800;
                }
            }
        }
    }
    &.betting-site-rhs {
        padding-inline: 0;
        .waf-head {
            @extend %px-4;
        }
        .betting-site {
            &-wrapper {
                overflow: hidden;
            }
            &-list {
                overflow-x: auto;
                box-sizing: border-box;
                @extend %px-4;
                @extend %gap-4;
                @extend %flex;
            }
            &-item {
                width: 85%;
                .item-wrap {
                    height: 13rem;
                }
            }
        }
        .swiper-button {
            top: 0;
        }
    }
}
.waf-betting-detail {
    @extend %px-4;
    @extend %py-7;
    .head {
        .title {
            @extend %mb-7;
            @extend %font-24-pb;
            @extend %secondary-800;
        }
        .sub-title {
            @extend %mb-7;
            @extend %capitalize;
            @extend %secondary-800;
            @extend %font-12-pm;
        }
    }
    .text {
        @extend %neutral-800;
        @extend %pb-7;
        @extend %font-16-pr;
        a {
            font-weight: 700;
            text-decoration: underline;
            @extend %primary-600;
        }
    }
    .betting-site-item {
        flex-wrap: wrap;
        background: var(--betting-primary-color, var(--neutral-800));
        @extend %mb-7;
        @extend %half-radius;
        @extend %p-4;
        @extend %flex;
        .item-content {
            background: var(--betting-secondary-color, var(--neutral-700));
            @extend %mb-5;
            @extend %gap-2;
            @extend %p-2;
            @extend %flex-sb-c;
            @extend %half-radius;
            .logo {
                width: 18rem;
                height: 3rem;
                img {
                    object-fit: contain;
                    object-position: left;
                    @extend %h-100;
                    @extend %w-100;
                }
            }
        }
        .item-description {
            @extend %pure-white-900;
            @extend %font-14-pm;
            @extend %text-center;
        }
        .star-rating {
            @extend %gap-2;
            @extend %flex-sb-c;
            .text {
                @extend %pb-0;
                @extend %pure-white-900;
                @extend %font-12-pm;
            }
            .icon {
                @extend %font-0;
                &::before {
                    @extend %primary-300;
                    @include icon(star);
                }
            }
        }
        .item-button {
            @extend %my-5;
            .btn-site {
                flex: 1;
                height: 3.4rem;
                @extend %gap-3;
                @extend %relative;
                @extend %flex-c-c;
                @extend %uppercase;
                @extend %font-10-pb;
                @extend %pure-white-900;
                @extend %success-800-bg;
                @extend %half-radius;
                &:after {
                    @include icon(drop-arrow);
                }
            }
        }
        .text {
            line-height: 1.5rem;
            @extend %pb-0;
            @extend %pure-white-900;
            @extend %text-center;
            @extend %font-9-pr;
        }
    }
}
@container betting-site (width > 992px) {
    .waf-betting-site {
        .star-rating {
            a {
                pointer-events: none;
            }
        }
        .betting-site-item {
            .item-wrap {
                flex-wrap: nowrap;
                height: 8rem;
                overflow: visible;
            }
            .item-number {
                width: 4%;
                height: 5rem;
            }
            .item-button {
                width: 25%;
                gap: var(--space-2);
                justify-content: center;
                padding-right: var(--space-4);
                .btn-site {
                    height: 3.4rem;
                    &.btn-read {
                        display: flex;
                    }
                    &.btn-visit {
                        font-size: 1rem;
                        flex-grow: 0;
                    }
                    &:after {
                        @include icon(drop-arrow);
                    }
                }
            }
            .item-content {
                width: 25%;
                height: 5rem;
                .item-content-wrap {
                    height: 100%;
                    padding: var(--space-4);
                    gap: var(--space-3);
                }
            }
            .item-description {
                width: 46%;
                margin-block: 0;
                padding-inline: var(--space-5) 0;
                position: unset;
                @include flex-config(flex, null, null, center);
                .description {
                    font-size: 1.4rem;
                    font-weight: 700;
                }
            }
        }
        .tooltip {
            order: 1;
            position: relative;
            &-data {
                width: 32rem;
                height: max-content;
                max-height: 17.5rem;
                visibility: hidden;
                opacity: 0;
                right: var(--space-4-neg);
                top: var(--space-4-neg);
                background: var(--pure-white-900);
            }
            .description {
                // .title {
                //     display: none;
                // }
                .text {
                    font-size: .9rem;
                    font-weight: 400;
                }
            }
            .info-icon {
                &[aria-expanded=true] {
                    & ~ .tooltip-data {
                        opacity: 1;
                        visibility: visible;
                        bottom: unset;
                    }
                    &::after {
                        top: var(--space-3-neg);
                        right: var(--space-3-neg);
                    }
                }
            }
        }
    }
}
@include mq(col-md) {
    .waf-betting-site {
        &.betting-site-rhs {
            padding: 0;
            .waf-head {
                width: 75%;
                padding-inline: 0;
                .title {
                    margin-top: 0;
                }
            }
            .betting-site {
                &-list {
                    gap: 0;
                    overflow-x: unset;
                    box-sizing: content-box;
                    padding-inline: var(--space-0);
                }
                &-item {
                    width: 100%;
                    .item-button {
                        .btn-visit {
                           font-size: 0.8rem;
                           flex: 1;
                        }
                    }
                }
            }
            .swiper-button {
                display: flex;
            }
        }
    }
    .waf-betting-detail {
        padding-inline: 0;
        .head {
            .sub-title {
                font-size: 1.4rem;
            }
            .title {
                font-size: 3.6rem;
            }
        }
    }
}
@include mq(col-lg) {
    .waf-betting-detail {
        background: var(--pure-white-900);
        border-radius: var(--half-radius);
        padding: var(--space-7);
    }
    .sticky-container-betting-site {
        position: relative;
        z-index: var(--z-tooltip-data);
    }
}