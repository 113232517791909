@use "../config" as *;
.swiper {
	&-button-next,
	&-button-prev {
		width: var(--swiper-button-width);
		aspect-ratio: 1/1;
		@extend %primary-200-bg;
		@extend %rounded-radius;
		@extend %flex-c-c;
		&.swiper-button-disabled {
			cursor: not-allowed;
		}
		&::after {
			font: 1rem/1 $font-icon;
			@extend %neutral-800;
		}
	}
	&-button-prev {
		&::after {
			@include icon(circle-arrow-left, 17);
		}
	}
	&-button-next {
		&::after {
			@include icon(circle-arrow-right, 17);
		}
	}
	&-pagination-bullet {
		width: 1rem;
		height: .2rem;
		border-radius: var(--half-radius);
		background-color: clr(neutral-900, 7);
		&-active {
			width: 2rem;
			height: .4rem;
			background-color: clr(neutral-900);
		}
	}
}