@use "../config" as *;
html {
    @extend %w-100;
    &.no-scroll,
    &.no-scroll body {
        height: var(--window-inner-height);
        touch-action: none;
        -ms-scroll-chaining: none;
        overscroll-behavior: none;
        -webkit-overflow-scrolling: auto;
        @extend %hidden;
    }
}
[v-cloak] {
    visibility: hidden;
}
.grecaptcha-badge {
    visibility: hidden;
}
:where(.img-box) {
    @extend %neutral-900-bg;
    @extend %half-radius;
    @extend %hidden;
}
img {
    display: block;
    height: auto;
    image-rendering: -webkit-optimize-contrast;
    @extend %w-100;
}
[class^="img-"] {
    @extend %relative;
    img {
        @extend %h-100;
        @extend %pos-tl;
        &[alt] {
            font-size: 1.2rem;
            @extend %neutral-900-7;
        }
    }
}
@each $width,
$height in $aspect-ratio {
    .img-#{$width}by#{$height} .img-box {
        padding-bottom: calc(($height / $width) * 100%);
    }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    @extend %m-0;
}
/* Firefox */
input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
}
input::-webkit-date-and-time-value {
    text-align: left;
}
.desktop {
    @extend %d-none;
}
.mobile {
    @extend %d-block;
}
@each $width in $flex-width {
    .m-w-#{$width} {
        width: calc($width * 1%);
        &-gap {
            width: calc((#{$width * 1%} - var(--space-2)));
        }
    }
}
//project css start
/* web setup css done */
/* project common css start */
.main-wrap {
    @include mq(col-lg) {
        padding-top: var(--header-height);
    }
}
// max-width style 
:where(.layout-wrapper) {
    max-width: var(--container-max-width);
    margin: auto;
}
// waf head common styles 
:where(.waf-head) {
    .title {
        min-height: 3rem;
        @extend %flex-n-c;
        @extend %secondary-800;
        @extend %uppercase;
        @extend %font-16-pb;
    }
    .head-wrap {
        @extend %flex-sb-c;
    }
    .head-tab {
        flex-shrink: 0;
        li {
            // border: 0.1rem solid var(--secondary-800);
            list-style: none;
            @extend %text-center;
            @extend %capitalize;
            @extend %half-radius;
        }
        a {
            min-width: 11rem;
            @extend %p-1-3;
            @extend %d-block;
            @extend %font-12-pb;
            @extend %neutral-900;
        }
    }
}
// buttons style starts 
button {
    span {
        pointer-events: none;
    }
}
.loadmore {
    padding: var(--space-2) var(--space-8);
    border: 0.1rem solid;
    @extend %rounded-radius;
    @extend %neutral-900;
}
.loadmore-wrap {
    margin: var(--space-4) auto;
    display: block;
    width: max-content;
}
.scroll-top {
    z-index: var(--z-scrolltop);
    position: fixed;
    bottom: calc(var(--header-height) + 2rem);
    right: 3rem;
    @extend %d-none;
    .scroll-top-btn {
        width: 4rem;
        aspect-ratio: 1/1;
        @extend %relative;
        @extend %primary-300-bg;
        @extend %half-radius;
        @extend %flex-c-c;
        &::after {
            @include icon(chevron-up, 16);
            @extend %secondary-800;
            @extend %pos-center;
        }
        .btn-text {
            @extend %font-0;
        }
    }
}
.home-page {
    .main-wrap {
        padding-top: var(--ticker-height);
        &:has(.waf-search) {
            padding-top: 0;
        }
    }
    &.sticky {
        .main-wrap {
            padding-top: 0;
        }
    }
    .waf-breadcrumb {
        @extend %d-none;
    }
}
.main-wrap {
    min-height: calc(var(--window-inner-height) - var(--header-height));
}
.waf-listing,
.waf-showcase {
    .article {
        &-thumbnail {
            @extend %relative;
        }
        &-content {
            .article-info {
                @extend %d-none;
            }
        }
        &-thumbnail-tagline {
            border-radius: .2rem;
            width: max-content;
            height: 2.2rem;
            gap: .2rem;
            @extend %secondary-800;
            @extend %p-1;
            @extend %flex-n-c;
            @extend %primary-300-bg;
            @include position(var(--space-3), var(--space-3), null, null);
            &:before {
                content: '';
                width: 1.2rem;
                height: 1.2rem;
                @include background(null, "svg/live-blinker.svg", left/cover no-repeat);
            }
            &::after {
                content: 'Live';
                @extend %font-12-pm;
            }
            p {
                @extend %font-0;
            }
        }
    }
}
.women {
    img {
        &.image-error {
            @include background(var(--secondary-50), "players/female.png", center / contain no-repeat);
            @extend %h-100;
        }
    }
}
img {
    &.image-error {
        @include background(var(--secondary-50), "players/0.png", center / contain no-repeat);
        @extend %h-100;
    }
}
img {
    &.team-image-error {
        @include background(var(--secondary-50), "teams/default-flag.png", center / cover no-repeat);
        @extend %h-100;
    }
}
//safari workaround for base font size
@media only screen and (max-width: $tablet-min-width) {
    :root {
        -webkit-text-size-adjust: none;
    }
}
// buttons style starts 
@include mq(col-md) {
    .mobile {
        display: none;
    }
    .desktop {
        display: block;
    }
    @each $width in $flex-width {
        .w-#{$width} {
            width: calc($width * 1%);
            &-gap {
                width: calc((#{$width * 1%} - var(--space-2)));
            }
        }
    }
}
@include mq(col-lg) {
    .home-page {
        .main-wrap {
            padding-top: calc(var(--header-height) + var(--ticker-height));
        }
        &.sticky {
            .main-wrap {
                padding-top: var(--header-height);
            }
        }
    }
    .scroll-top {
        bottom: 5rem;
        right: 5rem;
    }
}
.d-none {
    display: none;
}
::-webkit-scrollbar {
    width: 1rem;
    height: 0.4rem;
}
::-webkit-scrollbar-track {
    background: transparent;
    border-radius: var(--half-radius);
}
::-webkit-scrollbar-thumb {
    border-radius: var(--half-radius);
    background: var(--primary-600);
}
::-webkit-scrollbar-thumb:hover {
    border-radius: var(--half-radius);
    background: var(--primary-500);
}
@include mq(col-md) {
    .waf-row-vertical-space {
        padding-block: var(--space-8)
    }
}