@use "../config" as *;
.article-item {
    width: 100%;
}
.waf-listing,
.waf-showcase {
    --z-listing: 2;
    .article {
        &-wrap {
            @extend %flex-column;
        }
        &-thumbnail {
            @extend %w-100;
        }
        &-item {
            @extend %relative;
            @extend %full-radius;
            @extend %hidden;
        }
        &-title {
            @extend %mt-4;
            @include truncate(var(--_listing-line, 2), var(--_listing-title, 14));
        }
        &-content {
            z-index: var(--z-listing);
            flex-wrap: wrap;
            @extend %flex;
            @extend %relative;
            @extend %transparent-bg;
            @extend %w-100;
            @extend %p-2;
            @extend %neutral-900;
            .icon-b-share {
                @extend %neutral-900;
            }
            > a {
                @extend %w-100;
            }
            .readmore {
                @extend %d-none;
            }
            .reaction-section {
                @extend %d-none;
            }
            &-meta {
                .meta {
                    &::after {
                        @extend %neutral-900-bg;
                    }
                }
            }
        }
        &-meta {
            width: calc(100% - 3.5rem);
            min-height: 1.2rem;
            @extend %p-0;
            @extend %mt-auto;
            @extend %mb-1;
            @extend %gap-1;
            @extend %flex-n-fs;
            .meta {
                @extend %relative;
                @extend %font-10-pr;
                @extend %m-0;
                &:not(:first-child) {
                    @extend %pr-2;
                    &:after {
                        content: "";
                        width: 0.1rem;
                        height: 1rem;
                        @include position-combo(y-center, 0);
                    }
                }
                &::after {
                    @extend %neutral-400-bg;
                }
                &-category {
                    color: var(--secondary-600);
                    width: calc(100% - var(--space-6));
                    @extend %pos-tl;
                    @extend %font-10-pm;
                    &:after {
                        display: none;
                    }
                }
                &-category,
                &-author {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    @extend %font-10-pr;
                }
                &-date {
                    flex-shrink: 0;
                    order: 1;
                    @extend %font-10;
                }
                &-author {
                    &:empty {
                        display: none;
                    }
                }
            }
        }
    }
    .waf-head {
        .primary-tabs {
            @extend %d-none;
        }
    }
    .item-type-icon {
        width: max-content;
        max-width: 6.5rem;
        gap: var(--space-2);
        @include position(null, null, var(--space-4), var(--space-4));
        @extend %primary-300-bg;
        @extend %half-radius;
        @extend %font-12-pb;
        @extend %neutral-800;
        &::before {
            @extend %neutral-800;
        }
    }
    .item-type-photo {
        .item-type-icon {
            height: 2.5rem;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            @include position(1rem, null, null, 1rem);
            @extend %py-1;
            @extend %px-2;
            &::before {
                @include icon(gallery, 12);
            }
        }
    }
    .item-type-video {
        .article-info {
            display: block;
        }
        .timestamp {
            width: max-content;
            max-width: 6.5rem;
            gap: var(--space-2);
            @include position(-4rem, null, null, var(--space-4));
            @extend %primary-300-bg;
            @extend %half-radius;
            @extend %font-12-pb;
            @extend %neutral-800;
            @extend %flex-n-c;
        }
        .item-type-icon {
            @extend %d-none;
        }
        .timestamp {
            height: 2.5rem;
            @extend %p-2;
            &::before {
                @include icon(video, 12);
            }
        }
    }
    .item-type-article {
        .item-type-icon {
            @extend %d-none;
        }
    }
}
.waf-showcase {
    .waf-head {
        @extend %d-none;
    }
}
@include mq(col-md) {
    .article-list {
        @include grid(4, var(--space-4));
    }
}