@use "../config/" as *;
.waf-listing {
    --listing-title: 14;
    @extend %py-5;
    @extend %neutral-50-bg;
    .waf-head {
        @include drop-arrow();
        --_border-color: var(--secondary-800);
        --_more-color: var(--secondary-800);
    }
    .layout-wrapper {
        @extend %px-4;
    }
    .reaction-section,
    .modal-window,
    .primary-tabs,
    .filter-section,
    .loadmore-wrap,
    .pagination-wrap {
        @extend %d-none;
    }
    .first-list {
        @include listing-card(vertical);
        @include card-count(1.8, var(--space-4));
        @extend %mr-4-neg;
        @extend %pr-4;
        @extend %py-5;
    }
    .article {
        &-item {
            @extend %half-radius;
        }
        &-thumbnail {
            .img-box {
                @extend %half-radius;
                img {
                    object-fit: cover;
                }
            }
        }
        &-info {
            @extend %d-none;
        }
        &-description {
            @extend %d-none;
        }
        &-readmore {
            @extend %d-none;
        }
        &-meta {
            margin-top: var(--space-6);
            width: 100%;
            // .meta-author {
            //     @extend %d-none;
            // }
            .meta-category {
                @extend %font-10-pm;
                font-size: 1rem;
                max-width: 100%;
                @include position(var(--space-4), null, null, 0);
                @extend %secondary-800;
                &::after {
                    @extend %d-none;
                }
            }
            .meta-author,
            .meta-date {
                @extend %font-10-pm;
                @extend %neutral-500;
            }
        }
        &-content {
            padding-inline: 0;
            padding-top: var(--space-9);
        }
        &-title {
            margin-top: 0;
            @extend %font-14-pb;
            @extend %neutral-800;
        }
    }
    .tagline {
        @extend %d-none;
    }
}
@include mq(col-md) {
    .waf-listing {
        .first-list {
            margin-right: unset;
            padding-right: unset;
            @include card-count(3, var(--space-4), true);
        }
        .article-meta {
            .meta-date {
                font-size: 1rem;
            }
        }
    }
    .waf-home-news-listing {
        padding-block: var(--space-12);
        .layout-wrapper {
            padding-inline: 0;
        }
        .article-list {
            flex-wrap: nowrap;
            overflow-x: auto;
        }
    }
}
@include mq(col-xl) {
    .waf-listing {
        .first-list {
            padding-bottom: 0;
            @include card-count(6, var(--space-4), true);
        }
    }
}