@use "../config/" as *;
th,
td {
    border: 0.1rem solid clr(neutral-900, 3);
    @extend %p-2-1;
}
table {
    min-width: 100%;
    border-collapse: collapse;
    border: 0.1rem solid clr(neutral-900, 3);
}
.table {
    border-collapse: collapse;
    @extend %w-100;
    @extend %full-radius;
    @extend %hidden;
    &:has(>.table-left) {
        @extend %flex;
    }
    thead {
        @extend %primary-100-bg-8;
    }
    th,
    td {
        @extend %capitalize;
        @extend %text-left;
        @extend %p-2;
    }
    tr {
        @extend %relative;
        border-bottom: 0.1rem solid clr(neutral-50);
    }
    &-row {
        border-bottom: 0.1rem solid clr(neutral-50);
        @extend %w-100;
        @extend %flex;
        &-wrap {
            @extend %w-100;
            .row-head {
                @extend %flex;
            }
        }
    }
    &-head {
        @extend %primary-100-bg-8;
        .table-data {
            height: var(--_table-head-data-height, 3.2rem);
        }
    }
    &-left {
        @extend %w-40;
    }
    &-right {
        @extend %overflow;
        @extend %w-60;
    }
    &-data {
        flex-shrink: 1;
        height: var(--_table-data-height, 4.2rem);
        @extend %w-100;
        @extend %text-center;
        @extend %flex-c-c;
    }
    .text {
        @extend %font-12-pm;
        @extend %uppercase;
    }
}