@use "../config/" as *;
.footer-section-wrap {
    background-image: linear-gradient(to right, var(--secondary-800), var(--secondary-700));
    .layout-wrapper {
        @extend %p-8-4;
    }
    .footer-link {
        &-title {
            @extend %pb-9;
            @extend %pure-white-900;
            @extend %font-20-pb;
        }
        &-section {
            border-block: 0.1rem solid clr(secondary-50, 2);
            @extend %gap-13;
            @extend %flex-column;
            @extend %p-8-0;
            @extend %mt-8;
        }
        &-list {
            list-style: none;
            @extend %flex-column;
            @extend %gap-7;
            @extend %p-0;
        }
        &-text {
            @extend %font-15-pr;
            @extend %neutral-300;
        }
    }
    .footer-bottom-content {
        @extend %pt-8;
        @extend %pb-24;
        @extend %font-15-pr;
        @extend %neutral-300;
    }
    .social-link-wrapper {
        @extend %flex;
        gap: var(--space-2);
    }
    .logo {
        width: 11rem;
        height: 2.5rem;
        @extend %mb-5;
    }
    .secondary-partner {
        width: 8rem;
        height: 2.5rem;
        @extend %mt-11;
    }
    .social-link {
        &-anchor {
            width: 2.6rem;
            height: 2.6rem;
            border: 0.1rem solid clr(pure-white-900);
            @extend %p-2;
            @extend %d-block;
            @extend %circle-radius;
            @extend %hidden;
            @extend %flex-c-c;
            @extend %pure-white-900;
            @extend %transparent-bg;
        }
        @each $key, $value in $social {
            &-#{$key}::before {
                @include icon($key);
            }
        }
    }
}
@include mq(col-md) {
    .footer-section-wrap {
        .footer-link {
            &-list {
                flex-direction: row;
            }
            &-title {
                padding-bottom: var(--space-6);
                font-size: 2.5rem;
                line-height: 3rem;
            }
            &-section {
                gap: 3rem;
                margin-top: var(--space-8);
            }
        }
        .layout-wrapper {
            padding: var(--space-15) 0;
        }
        .footer-bottom-content {
            padding-top: var(--space-10);
            padding-left: var(--space-12);
            padding-right: var(--space-12);
            padding-bottom: var(--space-0);
            padding-inline: 0;
        }
        .logo-social-wrapper {
            display: flex;
            gap: var(--space-5);
            align-items: center;
        }
        .logo {
            width: 16.5rem;
            height: 3.5rem;
            margin-bottom: 0;
        }
        .secondary-partner {
            width: 9rem;
            height: 2.5rem;
            margin-top: var(--space-0);
        }
        .footer-media-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}