@use "../config/" as *;

.trending-player {
    .waf-head {
        @extend %flex;
        @extend %w-100;
        .head-wrap {
            @extend %w-100;
        }
        .title {
            border-bottom: 0;
            @extend %w-100;
            @extend %uppercase;
            @extend %secondary-800;
            @extend %font-16-pb;
        }
    }
    .head-tab {
        @extend %d-none;
    }
    .trending {
        &-list {
            overflow-x: auto;
            @extend %px-4;
            @extend %mx-4-neg;
            @extend %gap-4;
            @extend %flex;
        }
        &-item {
            border: .1rem solid var(--neutral-100);
            flex-shrink: 0;
            width: 35%;
            @extend %relative;
            @extend %p-3;
            @extend %half-radius;
        }
        &-thumbnail {
            width: 6.3rem;
            height: 6.3rem;
            aspect-ratio: 1/1;
            overflow: hidden;
            @extend %mx-auto;
            @extend %mb-2;
            @extend %secondary-50-bg;
            @extend %circle-radius;
        }
        &-content {
            @extend %text-center;
            .text {
                @extend %neutral-800;
                @extend %capitalize;
                @extend %font-12-pb;
            }
        }
        &-more {
            @extend %pos-tl;
            @extend %w-100;
            @extend %h-100;
        }
        &-action {
            .more-text {
                @extend %font-0;
            }
        }
    }
}
.trending-tag {
    background: linear-gradient(92.5deg, var(--secondary-800) -0.01%, var(--secondary-1000) 133.57%);
    @extend %p-8-4;
    .waf-head {
        @extend %mb-5;
        @include drop-arrow();
        .title {
            @extend %pure-white-900;
            @extend %uppercase;
            @extend %font-16-pb;
        }
    }
    .waf-body {
        overflow-x: auto;
        &::-webkit-scrollbar {
            height: 0;
        }
    }
    .trending {
        &-list {
            flex-wrap: wrap;
            width: 60rem;
            @extend %gap-4;
            @extend %flex;
        }
        &-item {
            height: 3.6rem;
            @extend %relative;
            @extend %half-radius;
            @extend %px-4;
            @extend %pure-white-900-bg-2;
        }
        &-wrap {
            @extend %h-100;
            @extend %gap-2;
        }
        &-thumbnail {
            @extend %d-none;
        }
        &-wrap {
            @extend %flex-n-c;
        }
        &-content {
            .text {
                @extend %pure-white-900;
                @extend %font-12-pm;
            }
        }
        &-action {
            &::after {
                @extend %pure-white-900;
                @include icon(drop-arrow);
            }
            .trending-more {
                @extend %w-100;
                @extend %h-100;
                @extend %pos-tl;
            }
            .more-text {
                @extend %font-0;
            }
        }
    }
}
.trending-team {
    background: linear-gradient(92.5deg, var(--secondary-800) -0.01%, var(--secondary-1000) 133.57%);
    @extend %p-8-4;
    .waf-head {
        @extend %mb-5;
        .title {
            @extend %pure-white-900;
            @extend %uppercase;
            @extend %font-16-pb;
        }
    }
    .head-tab {
        @extend %d-none;
    }
    .waf-body {
        overflow-x: auto;
        &::-webkit-scrollbar {
            height: 0;
        }
    }
    .trending {
        &-list {
            flex-wrap: wrap;
            width: 60rem;
            @extend %gap-4;
            @extend %flex;
        }
        &-item {
            height: 3.6rem;
            @extend %relative;
            @extend %half-radius;
            @extend %px-4;
            @extend %pure-white-900-bg-2;
        }
        &-wrap {
            @extend %h-100;
            @extend %gap-2;
        }
        &-thumbnail {
            width: 3rem;
            height: 2rem;
            border-radius: .2rem;
            .image {
                @extend %w-100;
                @extend %h-100;
            }
        }
        &-wrap {
            @extend %flex-n-c;
        }
        &-content {
            .text {
                @extend %capitalize;
                @extend %pure-white-900;
                @extend %font-12-pm;
            }
        }
        &-action {
            &::after {
                @extend %pure-white-900;
                @include icon(drop-arrow);
            }
            .trending-more {
                @extend %w-100;
                @extend %h-100;
                @extend %pos-tl;
            }
            .more-text {
                @extend %font-0;
            }
        }
    }
}
@include mq(col-lg) {
    .trending-player {
        .waf-head {
            .title {
                border-bottom: .1rem solid var(--neutral-50);
            }
        }
        .trending {
            &-list {
                overflow-x: unset;
                overflow-y: auto;
                flex-wrap: wrap;
                gap: var(--space-3);
                max-height: 37rem;
            }
            &-content {
                .text {
                    font-size: 1.4rem;
                }
            }
            &-item {
                width: calc(50% - .6rem);
            }
        }
    }
    .trending-team,.trending-tag {
        padding: var(--space-15) 0;
        background: linear-gradient(92.5deg, var(--secondary-800) -0.01%, var(--secondary-700) 133.57%);
        .trending {
            &-list {
                width: auto;
            }
        }
    }
}