@use "../config/" as *;

.webstory-listing {
    .first-list {
        padding-bottom: 0;
        margin-inline: var(--space-4-neg);
        padding-inline: var(--space-4);
        .article-content {
            position: absolute;
        }
    }
    .article-item {
        &::before {
            content: '';
            z-index: var(--z-overlay);
            pointer-events: none;
            background: linear-gradient(0deg, var(--secondary-800) 0%, clr(secondary-800,0) 61.75%);;
            @extend %w-100;
            @extend %h-100;
            @extend %pos-bl;
        }
    }
    .article-counter {
        width: calc(100% - var(--space-6));
        @include position(var(--space-3),var(--space-3),null,var(--space-3));
        @extend %flex;
        @extend %gap-1;
        .count-dash {
            height: .3rem;
            flex: 1;
            @extend %half-radius;
            @extend %neutral-100-bg;
        }
    }
    .article-content {
        bottom: 0;
        padding: 0 var(--space-3) var(--space-3);
    }
    .article-title {
        font-size: 1.2rem;
        line-height: 1.6;
        height: 3.6rem;
        @extend %pure-white-900;
    }
    .article-meta {
        margin-top: var(--space-5);
        .meta-category {
            @extend %d-none;
        }
        .meta {
            @extend %pure-white-900;
        }
    }
}
@include mq(col-md) {
    .webstory-listing {
        .layout-wrapper {
            padding-inline: 0;
        }
        .first-list {
            padding-inline: 0;
            margin-left: unset;
        }
        .article-item {
            width: 100%;
            flex: 1;
        }
    }
}