@use "./map" as *;
// scss variables
$base-space: 0.4rem;
$max-space-count: 25; // maximum count variable generation
$default-line-height: 1.6;
$body-font-size: 1.4rem;
$body-font-weight: 400;
// media query variables
$small-mobile-min-width: 0px;
$small-mobile-max-width: 360px;
$medium-mobile-min-width: 576px;
$mobile-max-width: 767px;
$tablet-min-width: 768px;
$tablet-max-width: 991px;
$desktop-min-width: 992px;
$large-desktop-min-width: 1200px;
$xl-desktop-min-width: 1600px;
// font variables
$font-primary: "PublicSans", sans-serif;
$font-icon: "waf-font-icon";
//  version variables
$image-version: "23.36";
$font-version: "80815819";
// css variables
:root {
  @each $color, $varients in $colors {
    @each $varient, $hsl in $varients {
      $h: nth($hsl, 1);
      $s: nth($hsl, 2);
      $l: nth($hsl, 3);
      --#{$color}-#{$varient}: hsl(#{$h} #{$s} #{$l});
      --hsl-#{$color}-#{$varient}: #{$h} #{$s} #{$l};
    }
  }
  // non color variables
  --base-font-size: 62.5%;
  --container-max-width: 100%;
  --content-width: var(--window-inner-width);
  --vh: 1vh;
  --vw: 1vw;
  --window-inner-height: calc(var(--vh, 1vh) * 100);
  --window-inner-width: calc(var(--vw, 1vw) * 100);
  --container-white-space: calc(
    (var(--window-inner-width) - var(--container-max-width)) / 2
  );
  // space variable
  @for $i from 1 through $max-space-count {
    --space-#{$i}: #{$base-space * $i};
    --space-#{$i}-neg: #{$base-space * $i * -1};
  }
  --space-0: 0;
  // use
  // --space-1 = 5px
  // --space-2 = 10px.... till --space-12 = 60px
  // this is according to base space variable
  --full-radius: 0.8rem;
  --half-radius: calc(var(--full-radius) / 2);
  --ads-width: 35rem;
  // **********this is just example for naming convention so please remove it and use it***********
  // height varible
  --header-height: calc(
    var(--header-top-height) + var(--header-bottom-height) +
      env(safe-area-inset-bottom, 0)
  );
  --header-top-height: 6.5rem;
  --header-bottom-height: 0rem;
  --social-height: 5rem;
  --swiper-button-width: 2.4rem;
  --secondary-menu-height: 8rem;
  --ticker-height: 3.4rem;
  --sticky-ad-height: 5rem;
  --writer-thumbnail: 11rem;
  // --header-height: 0rem;
  // zindex variable ********** assending order *******************
  @each $name, $index in $zindex {
    --z-#{$name}: #{$index};
  }
}
@media (min-width: $tablet-min-width) {
  :root {
    --container-max-width: calc(100% - 5rem);
    --filter-height: 7rem;
    --secondary-menu-height: 4.3rem;
    --sticky-ad-height: 9rem;
  }
}
@media (min-width: $desktop-min-width) {
  :root {
    // --container-max-width: 96rem;
    --header-top-height: 6.5rem;
    --header-bottom-height: 4rem;
    --header-height: calc(
      var(--header-top-height) + var(--header-bottom-height)
    );
    .sticky {
      --header-top-height: 5.5rem;
      --header-bottom-height: 0rem;
      --header-height: calc(
        var(--header-top-height) + var(--header-bottom-height)
      );
    }
  }
}
@media (min-width: $large-desktop-min-width) {
  :root {
    --container-max-width: 124.6rem;
  }
}
@media (min-width: $xl-desktop-min-width) {
  :root {
    --container-max-width: 140rem;
  }
}
