@use "../config/" as *;
.waf-banner {
    @extend %relative;
    @extend %p-8-4;
    @extend %neutral-50-bg;
    .img-box {
        border-radius: 0;
        img {
            aspect-ratio: 15 / 2;
            object-fit: contain;
        }
    }
    .banner-swiper {
        position: static;
        height: 16rem;
    }
    .article-wrap,
    .article-thumbnail,
    .img-box {
        @extend %h-100;
    }
    .article-thumbnail {
        .img-box {
            @extend %transparent-bg;
        }
    }
    .swiper {
        &-button-prev,
        &-button-next {
            @extend %d-none;
        }
        &-pagination {
            @include position(85%, 1.2rem, null, unset);
            @extend %text-right;
            &-bullet {
                width: 1.1rem;
                height: 1.1rem;
                @extend %mx-1;
                @extend %neutral-300-bg;
                @extend %rounded-radius;
                &-active {
                    background-color: clr(primary-300);
                }
            }
        }
    }
}
@include mq(col-md) {
    .waf-banner {
        padding-block: var(--space-15) var(--space-7);
        .layout-wrapper {
            padding-inline: 0;
        }
        .swiper-pagination {
            top: 90%;
            right: var(--container-white-space);
        }
    }
}