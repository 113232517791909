@use "variables" as *;
@use "map" as *;
// shimmer
@mixin shimmer($width: null, $height: null, $radius: 50vmax) {
  width: $width;
  height: $height;
  display: block;
  font-size: 0;
  background: var(--neutral-300);
  border-radius: $radius;
  overflow: hidden;
  position: relative;
  &::after {
    content: "";
    background-image: linear-gradient(
      -45deg,
      var(--neutral-100) 0%,
      var(--neutral-100) 40%,
      var(--neutral-300) 50%,
      var(--neutral-300) 60%,
      var(--neutral-300) 100%
    );
    position: absolute;
    inset: -150%;
    transform: translateX(-100%);
    animation: shimmerAnimation 2s linear 0s infinite normal forwards;
  }
}
// how to use
// @include shimmer()
// @include shimmer(100,20)
// @include shimmer(10,30,var(--space-2))
@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}
@mixin flex-config(
  $display: flex,
  $flex-direction: null,
  $justify-content: null,
  $align-items: null
) {
  display: $display;
  flex-direction: $flex-direction;
  justify-content: $justify-content;
  align-items: $align-items;
}
// how to use
// @include flex-config(); // just flex
// @include flex-config(flex,column);
// @include flex-config(flex,null,center,center);
@mixin position-combo($combo: null, $x: null, $y: null, $position: absolute) {
  position: $position;
  @if $combo == "tl" {
    top: 0;
    left: 0;
  } @else if $combo == "tr" {
    top: 0;
    right: 0;
  } @else if $combo == "bl" {
    bottom: 0;
    left: 0;
  } @else if $combo == "br" {
    right: 0;
    bottom: 0;
  } @else if $combo == "inset" {
    inset: 0;
  } @else if $combo== "center" {
    top: 50%;
    left: 50%;
    translate: -50% -50%;
  } @else if $combo== "y-center" {
    top: 50%;
    translate: 0 -50%;
    right: $x;
    left: $y;
  } @else if $combo== "x-center" {
    left: 50%;
    translate: -50% 0;
    top: $x;
    bottom: $y;
  }
}
// @include position-combo(tl); // top == left == 0
// @include position-combo(center);
@mixin position(
  $top: null,
  $right: null,
  $bottom: null,
  $left: null,
  $position: absolute
) {
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  position: $position;
}
// how to use
// @include position(1rem,2rem,3rem,4rem); // top right bottom left
// @include position(1rem,2rem,3rem,4rem,fixed); // top right bottom left fixed
@mixin card-count($count, $gap: null, $wrap: false, $update: false) {
  @if $update ==false {
    @include flex-config(flex);
    @if $wrap==true {
      flex-wrap: wrap;
    } @else {
      overflow-x: auto;
    }
    & > * {
      flex-shrink: 0;
    }
  }
  $availableWidth: calc(100% - (ceil($count) - 1) * $gap);
  @if $gap !=null {
    gap: $gap;
  }
  & > * {
    width: calc($availableWidth / $count);
  }
}
// how to use
// @include card-count(2, var(--space-4));
// @include card-count(2.5, var(--space-4));
// @include card-count(3, var(--space-4), true); For wrapping
// @include card-count(4, var(--space-6), false, true); // For only updating card-count in media query.
@mixin bg($color, $opacity: 10) {
  $color-hsla: hsl(var(--hsl-#{$color}) / calc($opacity * 0.1));
  background-color: $color-hsla;
}
@mixin color($color, $opacity: 10) {
  $color-hsla: hsl(var(--hsl-#{$color}) / calc($opacity * 0.1));
  color: $color-hsla;
}
// how to use
// @include bg(white,7) // color with opacity
// @include bg(black) // solid color
@mixin dropdown($status, $animation-delay: 300ms) {
  @if $status ==close {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    pointer-events: none;
    @include transition(clip-path $animation-delay linear);
  } @else if $status ==open {
    pointer-events: all;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  }
}
@mixin dropup($status, $animation-delay: 300ms) {
  @if $status ==close {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
    @include transition(clip-path $animation-delay linear);
  } @else if $status ==open {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  }
}
@mixin slide-left($status, $animation-delay: 300ms) {
  @if $status ==close {
    clip-path: polygon(100% 0, 100% 0%, 100% 100%, 100% 100%);
    @include transition(clip-path $animation-delay linear);
  } @else if $status ==open {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  }
}
@mixin fade-in($status, $animation-delay: 300ms) {
  @if $status ==close {
    opacity: 0;
    pointer-events: none;
    @include transition(opacity $animation-delay linear);
  } @else if $status ==open {
    pointer-events: inherit;
    opacity: 1;
  }
}
@mixin custom-scroll {
  &::-webkit-scrollbar {
    width: 0.8rem;
    height: 0.4rem;
  }
  &::-webkit-scrollbar-track {
    background: clr(neutral-100, 2);
    border-radius: var(--half-radius);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: var(--half-radius);
    background: var(--neutral-300);
  }
  &::-webkit-scrollbar-thumb:hover {
    border-radius: var(--half-radius);
    background: var(--neutral-900);
  }
}
@mixin line-clamp(
  $line-number,
  $font-size,
  $line-height: null,
  $font-family: null
) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: $line-number;
  @if $font-family != null {
    font-family: $font-family;
  }
  font-size: $font-size;
  @if $line-height != null {
    line-height: $line-height;
  }
}
// how to use
// @include truncate(line number, font-size,line-height, font-family)
// @include truncate(3, 20rem)
// @include truncate(3, 1vh)
// @include truncate(3, 20rem,34,$font-pr)
@mixin truncate(
  $line-number,
  $font-size,
  $line-height: null,
  $font-family: null
) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: $line-number;
  font-family: $font-family;
  font-size: calc($font-size * 0.1rem);
  @if $line-height !=null {
    $line-height: calc($line-height * 0.1rem);
  } @else if $line-height ==null {
    $line-height: calc(($font-size * 0.1rem) * 1.6);
  }
  line-height: $line-height;
  height: calc($line-number * $line-height);
}
// how to use
// @include truncate(line number, font-size,line-height, font-family)
// @include truncate(3, 20)
// @include truncate(3, 20,34,$font-pr)
@mixin truncate-text($line-number, $height: auto, $update: false) {
  @if $update ==false {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  -webkit-line-clamp: $line-number;
  @if $height !=auto {
    height: $height * 0.1rem;
  }
}
// how to use
// @include truncate-text(3);
// @include truncate-text(3, 12rem); // Set fixed height
// @include truncate-text(4, 18rem, true); // Update truncate parameters in mixin
@mixin truncate-line() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
// how to use
// Use for truncating one line
// @include truncate-line();
@mixin grid($count, $gap: var(--space-2), $update: false) {
  @if $update ==false {
    display: grid;
  }
  gap: $gap;
  grid-template-columns: repeat($count, minmax(0, 1fr));
}
// how to use
// @include grid(count of columns , space);
// @include grid(3,var(--space-2);
// @include grid(3,3rem);
// @include grid(3, var(--space-6), true); // For only updating values
// map
// change map as per project
@mixin icon($name, $size: 14) {
  $icon: map-get($icons, $name);
  font-family: $font-icon;
  @if $icon !=null {
    content: $icon;
    font-size: $size * 0.1rem;
    line-height: 1;
  } @else {
    @error "Icon '#{$name}' not found in $icons map.";
  }
}
@mixin user-setting($name, $size: 14) {
  $user-setting: map-get($user-setting, $name);
  font-family: $font-icon;
  @if $user-setting !=null {
    content: $user-setting;
    font-size: $size * 0.1rem;
    line-height: 1;
  } @else {
    @error "User-Setting '#{$name}' not found in $user-setting map.";
  }
}
// how to use
// @include icon(arrow-down)
// @include icon(arrow-down,14,null)
@mixin border(
  $width: 1,
  $color: null,
  $opacity: 10,
  $direction: all,
  $style: solid
) {
  $opacity-decimal: calc($opacity / 10);
  $color-hsla: hsl(var(--hsl-#{$color}) / $opacity-decimal);
  @if $direction ==all {
    border: calc($width * 0.1rem) $style $color-hsla;
  } @else if $direction ==left {
    border-left: calc($width * 0.1rem) $style $color-hsla;
  } @else if $direction ==right {
    border-right: calc($width * 0.1rem) $style $color-hsla;
  } @else if $direction ==top {
    border-top: calc($width * 0.1rem) $style $color-hsla;
  } @else if $direction ==bottom {
    border-bottom: calc($width * 0.1rem) $style $color-hsla;
  } @else if $direction ==y {
    border-block: calc($width * 0.1rem) $style $color-hsla;
  } @else if $direction ==x {
    border-inline: calc($width * 0.1rem) $style $color-hsla;
  } @else {
    @error "Invalid direction #{$direction}, must be one of: all, left, right, top, bottom.";
  }
}
// how to use
// @include border(1,c-purple);
// @include border(1,c-purple,3,left,dotted);
// @include border(border-width,border-color,border-color-opacity,border-position,border-style);
// ***** color name is got through map name so use same name as used in map
@mixin overlay {
  position: relative;
  &::before {
    content: "";
    background-image: linear-gradient(to top, var(--neutral-900), transparent);
    pointer-events: none;
    z-index: var(--z-overlay);
    @include position-combo(inset);
  }
}
// how to use
// use this inside that element where we need to add overlay
// @include overlay()
@mixin pattern-common($pseudo-element: before) {
  position: relative;
  &::#{$pseudo-element} {
    content: "";
    pointer-events: none;
  }
}
@mixin listing-card($type: card, $width: 14rem, $items: null) {
  @if $type==horizontal {
    .article {
      &-wrap {
        flex-direction: row;
      }
      &-thumbnail {
        width: $width;
        &::before {
          content: unset;
        }
      }
      &-content {
        width: calc(100% - $width);
        position: unset;
        color: var(--neutral-900);
        .icon-b-share {
          color: var(--neutral-900);
        }
      }
      &-meta {
        .meta {
          &::after {
            background-color: var(--neutral-400);
          }
        }
      }
    }
  }
  @if $type==overlay {
    .article {
      &-content {
        width: 100%;
        color: var(--neutral-100);
        @include position-combo(bl);
        a,
        .icon-b-share {
          color: var(--neutral-100);
        }
        .timestamp {
          color: inherit;
        }
      }
      &-meta {
        .meta {
          &::after {
            background-color: var(--neutral-400);
          }
        }
      }
      &-thumbnail {
        width: 100%;
        @include overlay();
      }
    }
  }
  @if $type==vertical {
    .article {
      &-thumbnail {
        width: 100%;
        &::before {
          content: unset;
        }
      }
      &-wrap {
        flex-direction: column;
      }
      &-content {
        width: 100%;
        position: relative;
        color: var(--neutral-900);
        .icon-b-share {
          color: var(--neutral-900);
        }
      }
      &-meta {
        .meta {
          &::after {
            background-color: var(--neutral-400);
          }
        }
      }
    }
  }
}
// how to use
// @include listing-card(rhs);
// @include listing-card(card);
// @include listing-card(overlay);
@mixin bgImg($image-location) {
  background-image: url("/static-assets/images/#{$image-location}?v=#{$image-version}");
}
// how to use
// @include bgImg("svg/logo.svg") // make sure to add double quotes
@mixin background($bg-color: null, $bg-Img: null, $bg-Props...) {
  @if $bg-Img ==null {
    background: $bg-Props;
  } @else if $bg-color ==null {
    background: url("/static-assets/images/#{$bg-Img}?v=#{$image-version}")
      $bg-Props;
  } @else {
    background: $bg-color
      url("/static-assets/images/#{$bg-Img}?v=#{$image-version}")
      $bg-Props;
  }
}
// how to use
// background(var(--neutral-100), "cssimages/svg/vector.svg", center / contain no-repeat);
@mixin hamburger() {
  .btn-hamburger {
    width: 6.5rem;
    height: var(--header-height);
    padding-bottom: env(safe-area-inset-bottom);
    position: relative;
    @extend %primary-300-bg;
    @extend %px-4;
    @extend %flex-column-c-c;
    .line {
      width: 100%;
      height: 0.3rem;
      background-color: var(--secondary-800);
      margin-bottom: var(--space-2);
      transition: 300ms transform;
      @extend %rounded-radius;
      &-bottom {
        margin-bottom: 0;
      }
      &-top,
      &-bottom {
        width: 60%;
        margin-left: auto;
      }
    }
    &[aria-expanded="true"] {
      .line {
        &-top {
          width: 100%;
          transform: translateY(1rem) rotate(45deg);
        }
        &-bottom {
          width: 100%;
          transform: translateY(-1.2rem) rotate(-45deg);
        }
        &-middle {
          opacity: 0;
        }
      }
    }
  }
}
// common hamburger
@mixin backdropBlur($blur) {
  backdrop-filter: blur($blur);
  -webkit-backdrop-filter: ($blur);
}
// backdrop filter with webkit make sure webkit is written first in sequence
// how to use
// @include backdropBlur(1rem)
@mixin mq($breakpoint) {
  @if ($breakpoint ==hover) {
    @media (hover: hover) and (pointer: fine) {
      @content;
    }
  } @else {
    $size: map-get(
      $map: $media-query,
      $key: $breakpoint,
    );
    @media (min-width: $size) {
      @content;
    }
  }
}
// how to use
// @include mq(col-md) // for media-query tablet
// @include mq(col-lg) // for media-query small screen laptop
// @include mq(col-xl) // for media-query medium screen laptop
// @include mq(col-xl) // for media-query large screen laptop
@mixin default-wrapper() {
  max-width: var(--container-max-width);
  margin-inline: auto;
}
// Default Wrapper
@mixin pattern-common($pseudo-element: before, $parentPosition: relative) {
  position: $parentPosition;
  &::#{$pseudo-element} {
    content: "";
    pointer-events: none;
  }
}
// Pattern Common
@mixin drop-arrow() {
  .head-tab a {
    border: 0.1rem solid var(--_border-color, var(--pure-white-900));
    color: var(--_more-color, var(--pure-white-900));
    @extend %gap-2;
    @extend %flex-n-c;
    @extend %half-radius;
    @extend %capitalize;
    @extend %font-12-pb;
    &:after {
      font-weight: 500;
      width: 1.4rem;
      @include icon(drop-arrow, 14);
    }
    &::before {
      width: 1.4rem;
      height: 0.1rem;
      background: var(--_more-color, var(--pure-white-900));
    }
    @include mq(col-lg) {
      transition: 0.3s;
      &::before {
        content: "";
        transition: 0.3s;
      }
      &::after {
        width: 0;
        overflow: hidden;
        transition: 0.3s;
      }
      &:hover {
        &::before {
          width: 0;
          overflow: hidden;
        }
        &::after {
          width: 1.4rem;
          @include icon(drop-arrow, 14);
        }
      }
    }
  }
}
@mixin square($size) {
  height: $size;
  width: $size;
}
@mixin btn-animation() {
  width: max-content;
  height: 2.5rem;
  border: 0.1rem solid var(--secondary-800);
  @extend %transition;
  @extend %font-12-pb;
  @extend %secondary-800;
  @extend %half-radius;
  @extend %capitalize;
  @extend %flex-c-c;
  @extend %gap-2;
  @extend %p-2;
  &:after {
    width: 1.4rem;
    @include icon(drop-arrow, 14);
    @extend %transition;
  }
  &::before {
    content: "";
    width: 1.4rem;
    height: 0.1rem;
    background-color: var(--neutral-900);
    @extend %transition;
  }
  @include mq(col-md) {
    height: 3rem;
    font-size: 1.2rem;
    &::after {
      width: 0;
      overflow: hidden;
    }
    &:hover {
      &::before {
        width: 0;
        overflow: hidden;
      }
      &::after {
        width: 1.4rem;
        @include icon(drop-arrow, 14);
      }
    }
  }
}
@mixin separator(
  $space: 100%,
  $type: x,
  $thickness: 0.1rem,
  $colorVal: pure-white-900,
  $opacity: 2
) {
  $color: hsl(var(--hsl-#{$colorVal}) / #{$opacity * 0.1});
  // Set common styles for the separator
  // Horizontal separator
  @if $type ==y {
    // Default styles
    position: relative;
    &::after {
      content: "";
      width: $thickness;
      height: $space;
      background-color: $color;
      @include position-combo(y-center, 0);
    }
  }
  // Vertical separator
  @else if $type ==x {
    position: relative;
    &::after {
      content: "";
      width: $space;
      height: $thickness;
      background-color: $color;
      @include position-combo(x-center, null, 0);
    }
  }
}
// how to use
// @include separator(80%);
// @include separator(80%.x,.2rem,black,2);
// @include separator(length, direction, thickness , color, opacity);
