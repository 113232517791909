@use "../config/" as *;

.waf-poll {
    @extend %mx-4;
    @extend %p-4;
    @extend %pure-white-900-bg;
    .layout-wrapper {
        padding-inline: 0;
    }
    .waf-head {
        border-bottom: .1rem solid var(--neutral-50);
        @extend %mb-5;
        @extend %pb-3;
        @extend %flex;
    }
    .banner-wrapper {
        @extend %mb-5;
    }
    .poll {
        &-wrap {
            @extend %p-4;
            @extend %neutral-50-bg;
        }
        &-title {
            @extend %mb-4;
            .title {
                @extend %neutral-900;
                @extend %font-16-pb;
            }
        }
    }
    .radio-box {
        &:not(:last-child) {
            @extend %mb-4;
        }
        input {
            opacity: 0;
            left: 0;
            cursor: pointer;
            @extend %d-none;
            @extend %pos-y-center;
            &:checked {
                ~ .checkmark {
                    border: 0.1rem solid var(--secondary-800);
                    &:after {
                        content: '';
                    }
                }
            }
        }
        .checkmark {
            height: 2rem;
            width: 2rem;
            border: 0.1rem solid var(--secondary-800);
            left: 0;
            @extend %circle-radius;
            @extend %pos-y-center;
            &:after {
                width: 1.2rem;
                height: 1.2rem;
                @extend %circle-radius;
                @extend %secondary-800-bg;
                @extend %pos-center;
            }
        }
        .radio-data {
            @extend %secondary-800;
            @extend %pl-7;
            @extend %font-16-pr;
        }
        .radio-container {
            cursor: pointer;
            @extend %relative;
        }
        &-text {
            @extend %text-center;
            @extend %neutral-900;
            @extend %font-16-pr;
        }
        &-wrap {
            @extend %p-4;
            @extend %pure-white-900-bg;
            @extend %half-radius;   
        }
    }
    .btn-submit {
        min-height: 3.6rem;
        @extend %mt-4;
        @extend %flex-c-c;
        @extend %w-100;
        @extend %primary-300-bg;
        .btn-text {
            @extend %font-12-pb;
            @extend %uppercase;
            @extend %secondary-800;
        }
        &.btn-disabled {
            opacity: .5;
            pointer-events: none;
        }
    }
}
.poll-active {
    .waf-showcase {
        .layout-wrapper {
            align-self: start;
        }
    }
}
@include mq(col-lg) {
    .waf-poll {
        margin-inline: 0;
        .layout-wrapper {
            height: 100%;
            display: flex;
            flex-direction: column;
            width: 100%;
            padding-right: 0;
            gap: 0;
        }
    }
}