@use "../config/" as *;
.waf-scorestrip {
    background: var(--primary-300);
    @extend %py-5;
    .accordion-head,
    .card-venue,
    .card-number,
    .time,
    .card-footer-text,
    .countdown-wrapper,
    .match-time,
    .short-name,
    .dropdown-group,
    .run-rate,
    .swiper-button {
        @extend %d-none;
    }
    .waf-head {
        @extend %d-none;
        @extend %px-4;
        @extend %pb-4;
        @include drop-arrow();
        .title {
            @extend %font-16-pb;
            @extend %uppercase;
            @extend %pure-white-900;
        }
    }
    .card {
        &-list {
            overflow-x: auto;
            box-sizing: border-box;
            @extend %px-4;
            @extend %gap-4;
        }
        &-head {
            border-bottom: .1rem solid var(--neutral-50);
            @extend %mb-2;
            @extend %pb-2;
            @extend %flex-n-c;
        }
        &-label {
            width: 16rem;
            @include truncate-text(1);
            @extend %font-10-pm;
            @extend %neutral-500;
        }
        &-item {
            padding-block: 1rem;
            flex-shrink: 0;
            min-width: 85%;
            max-width: 31rem;
            width: max-content;
            @extend %px-2;
            @extend %half-radius;
            @extend %pure-white-900-bg;
            &.recent {
                .status {
                    @extend %primary-900;
                }
            }
            &.super-over {
                .team-data-wrap {
                  white-space: nowrap;
                }
                .team-name {
                    &.full-name {
                        width: 10rem;
                    }
                }
            }
        }
        &-footer {
            .btn-more {
                cursor: pointer;
                @extend %h-100;
                @extend %w-100;
                @extend %pos-tl;
                .btn-text {
                    @extend %font-0;
                }
            }
        }
    }
    .live {
        .status {
            gap: .5rem;
            font-weight: 700;
            @extend %flex-n-c;
            &::before {
                content: '';
                width: 1.5rem;
                height: 1.5rem;
                @extend %flex;
                @include background(null, "svg/live.svg", center / contain no-repeat);
            }
        }
    }
    .recent {
        .team-won {
            .team-data {
                &:last-child {
                    .score {
                        @extend %neutral-800;
                    }
                }
            }
        }
    }
    .upcoming {
        .team-wrapper {
            @extend %relative;
        }
        .team-name {
            &.full-name {
                width: 18rem;
            }
        }
        .match-time {
            right: 0;
            @extend %flex;
            @extend %font-14-pb;
            @extend %neutral-800;
            @extend %pos-y-center;
        }
    }
    .status {
        order: 1;
        text-wrap: nowrap;
        @extend %pl-2;
        @extend %font-10-pm;
        @extend %neutral-800;
        @extend %uppercase;
        @extend %ml-auto;
    }
    .date-time {
        order: -1;
        line-height: 1.2rem;
        text-wrap: nowrap;
        @extend %mr-2;
        @extend %relative;
        &::after {
            content: '';
            width: .1rem;
            right: var(--space-1-neg);
            @extend %pos-y-center;
            @extend %h-70;
            @extend %neutral-500-bg;
        }
        .date {
            @extend %font-10-pm;
            @extend %neutral-500;
        }
    }
    .score {
        @extend %neutral-400;
        @extend %font-12-pb;
    }
    .overs {
        @extend %neutral-400;
        @extend %font-10-pm;
    }
    .team {
        height: 2.5rem;
        @extend %flex-sb-c;
        &-data-wrap {
            @extend %gap-1;
            @extend %flex-n-c;
        }
        &-data {
            line-height: 1.4rem;
            @extend %gap-1;
            @extend %flex-n-c;
        }
        &-wrapper {
            @extend %relative;
            @extend %gap-1;
            @extend %flex-column;
        }
        &-info {
            @extend %gap-2;
            @extend %flex-n-c;
        }
        &-name {
            @extend %font-10-pb;
            @extend %uppercase;
            @extend %neutral-800;
            &.full-name {
                width: 12rem;
                @include truncate-text(2);
            }
        }
        &-image {
            border: .1rem solid var(--neutral-50);
            width: 2.5rem;
            height: 1.5rem;
            flex-shrink: 0;
        }
        &-current {
            .team-data {
                &:last-child {
                    .score {
                        @extend %neutral-800;
                    }
                }
            }
        }
    }
    .score-super-over {
        @extend %pl-1;
        @extend %relative;
        &:before {
            content: '';
            width: .1rem;
            left: 0;
            @extend %neutral-200-bg;
            @extend %pos-y-center;
            @extend %h-100;
        }
    }
    &.waf-shimmer {
        .full-name {
            @extend %font-0;
            @include shimmer(10rem, 1.2rem, var(--full-radius));
        }
        .score,
        .overs,
        .status,
        .card-label {
            @extend %font-0;
        }
        .card-label {
            @include shimmer(12rem, 1.2rem, var(--full-radius));
        }
        .date-time {
            .date {
                @extend %font-0;
                @include shimmer(5.5rem, 1.2rem, var(--full-radius));
            }
        }
        .status {
            @include shimmer(4.2rem, 1.5rem, var(--full-radius));
            &:before {
                content: unset;
            }
        }
        .team-data {
            @include shimmer(8rem, 1.5rem, var(--full-radius));
            @extend %font-0;
        }
    }
}
@include mq(col-md) {
    .waf-scorestrip {
        padding-block: var(--space-8);
        background: linear-gradient(92.5deg, var(--secondary-800) -0.01%, var(--secondary-600) 133.57%);
        .waf-head {
            padding-inline: 0;
            display: block;
            .head-tab {
                padding-right: 7rem;
            }
        }
        .card {
            &-list {
                padding-inline: 0;
                gap: 0;
                box-sizing: content-box;
                overflow-x: unset;
            }
            &-item {
                min-width: 30rem;
                min-height: 10.4rem;
                max-width: 40rem;
                margin-right: var(--space-4);
            }
        }
        .swiper {
            position: static;
            margin-inline: calc(var(--container-white-space)* -1);
            padding-inline: calc(var(--container-white-space));
        }
        .swiper-button {
            display: flex;
            top: var(--space-8);
            right: var(--container-white-space);
            &-prev {
                left: auto;
                right: calc(var(--container-white-space) + 3.3rem);
            }
            &-prev,
            &-next {
                &.swiper-button-disabled {
                    background: var(--neutral-50);
                    opacity: .8;
                }
                transition: 300ms background-color;
                &:hover {
                    background-color: var(--primary-500)
                }
            }
        }
    }
}