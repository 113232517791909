@use "../config" as *;

.waf-read-more {
    @extend %mb-5;
    @extend %p-7-4;
    .waf-head {
        // extend giving error hence not used.
        gap: var(--space-2);
        @include flex-config(flex,column);
        @extend %mb-5;
        img {
            height: 4rem;
            width: auto;
            margin-inline: auto;
        }
        .title {
            @extend %font-20-pb;
            @extend %capitalize;
            @extend %neutral-800;
        }
    }
    .read-info {
        @include position(null,0,var(--space-6-neg),null);
        .btn-text {
            border: .1rem solid var(--secondary-800);
            @extend %gap-2;
            @extend %flex-n-c;
            @extend %transition;
            @extend %half-radius;
            @extend %p-1-2;
            @extend %uppercase;
            @extend %font-12-pb;
            @extend %secondary-800;
            &.less {
                @extend %d-none;
            }
            &:after {
                width: 0;
                overflow: hidden;
                transition: .3s;
                font-weight: 500;
                @include icon(drop-arrow, 14);
            }
            &::before {
                content: '';
                width: 1.4rem;
                height: .1rem;
                background-color: var(--secondary-800);
                @extend %transition;
            }
        }
        &[aria-expanded=true] {
            bottom: var(--space-2-neg);
            .btn-text {
                &.less {
                    @extend %flex;
                }
                &.more {
                    @extend %d-none;
                }
            }
            & ~ .readmore-list {
                height: 100%;
            }
        }
        &:hover {
            .btn-text {
                &::before {
                    width: 0;
                    overflow: hidden;
                }
                &::after {
                    width: 1.4rem;
                    @include icon(drop-arrow, 14);
                }
            }
        }
    }
    .readmore {
        &-wrap {
            @extend %relative;
        }
        &-list {
            height: 14.7rem;
            overflow: hidden;
            transition: height 0.5s ease-out;
        }
        &-item {
            @extend %mb-5;
            .title {
                @extend %font-16-pb;
                @extend %neutral-800;
                @extend %mb-3;
            }
            .text {
                @extend %neutral-800;
                @extend %font-16-pr;
                @extend %neutral-800;
                &:not(:last-child) {
                    @extend %mb-4;
                }
            }
        }
    }
}
@include mq(col-md) {
    .waf-read-more {
        padding-inline: 0;
        .read-info {
            right: 0;
            bottom: var(--space-7-neg);
        }
        .readmore-list {
            height: 9rem;
        }
    }
}
@include mq(col-lg) {
    .waf-read-more {
        .waf-head {
            flex-direction: row;
            img {
                height: 4.2rem;
                margin-inline: auto 0;
            }
        }
    }
}