@use "../config/" as *;
.tab {
    &s {
        list-style: none;
        gap: .2rem;
        @extend %half-radius;
        @extend %flex;
        @extend %pl-0;
        @extend %w-100;
        .btn-text,
        span {
            @extend %neutral-600;
            @extend %uppercase;
            @extend %font-12-pm;
        }
    }
    &-name {
        flex: 1;
        height: 4rem;
        cursor: pointer;
        @extend %pure-white-900-bg;
        &:first-child {
            border-radius: var(--half-radius) 0 0 var(--half-radius);
        }
        &:last-child {
            margin-right: .2rem;
            // border-radius: 0 var(--half-radius) var(--half-radius) 0;
        }
        &.active,
        &.tab-active {
            @extend %primary-300-bg;
            .btn-text,
            span {
                font-weight: 700;
            }
        }
    }
    &-anchor {
        @extend %flex-c-c;
        @extend %w-100;
        @extend %h-100;
    }
}