@use "../config/" as *;
.waf-series-listing {
    @extend %pure-white-900-bg;
    @extend %mx-4;
    @extend %my-4;
    @extend %half-radius;
    .head-wrap {
        @include border(1, neutral-400, 1, bottom);
        @extend %pb-4;
    }
    .tagline {
        @extend %d-none;
    }
    .first-list {
        padding-bottom: 0;
        @include listing-card(horizontal);
        @include card-count(1, var(--space-4), true);
        .article {
            &-title {
                @extend %mt-3;
            }
            &-meta {
                margin-top: var(--space-3);
                width: 100%;
            }
            &-content {
                padding: var(--space-4);
                position: relative;
            }
            &-thumbnail {
                width: 17rem;
                .img-box {
                    height: 100%
                }
            }
            &-item {
                @include border(1, neutral-50);
            }
        }
        .meta-category {
            left: var(--space-4);
            top: var(--space-3);
        }
    }
    .reaction-section {
        @extend %d-none;
    }
    .list-group-wrap {
        @extend %d-none;
    }
}
.waf-series-ad {
    @extend %pure-white-900-bg;
    @extend %mx-4;
    @extend %half-radius;
    @extend %p-4;
    @extend %my-10;
    .head-wrap {
        @include border(1, neutral-400, 1, bottom);
        @extend %pb-4;
    }
    .event {
        &-listing {
            @include listing-card(horizontal);
            @include card-count(1, var(--space-4), true);
            @extend %mt-4;
        }
        &-meta {
            margin-top: .6rem;
            .meta {
                @extend %neutral-600;
                &-author,
                &-category {
                    @extend %d-none;
                }
                &-date,
                &-year {
                    @extend %font-10-pm;
                }
            }
        }
        &-wrap {
            gap: var(--space-2);
            @include flex-config(flex, null, center, center);
        }
        &-thumbnail {
            width: 35%;
            .image {
                aspect-ratio: 4 / 3;
                object-fit: cover;
                @extend %half-radius;
            }
        }
        &-content {
            width: calc(65% - var(--space-2));
        }
        &-title {
            @include border(1, neutral-400, 3, bottom, dashed);
            @extend %pb-1;
            .title {
                line-height: 2rem;
                @extend %secondary-800;
                @extend %font-14-pb;
            }
        }
    }
}
@include mq(col-md) {
    .waf-series-listing {
        margin-top: var(--space-8);
        .first-list {
            @include listing-card(vertical);
            @include card-count(1, var(--space-4), true);
        }
        .list-group-wrap {
            flex-shrink: 0;
            padding: var(--space-5) 0;
            gap: var(--space-4);
            @include flex-config(flex, column);
        }
        .second-list {
            height: 100%;
            .article-wrap {
                height: 100%;
            }
            .article-content {
                height: 100%;
            }
            .article-meta {
                height: max-content;
                margin-top: auto;
            }
        }
        .second-list,
        .third-list {
            @include listing-card(vertical);
            @include card-count(1, var(--space-4), true);
        }
    }
    .waf-series-ad {
        margin: var(--space-8) 0 0;
        padding-block: var(--space-5);
        height: 100%;
    }
    .waf-row-series-listing {
        padding-top: var(--space-8);
        .site-content {
            height: 100%;
        }
    }
}
@include mq(col-lg) {
    .waf-series-listing {
        .waf-body {
            display: flex;
            gap: var(--space-6);
        }
        padding-bottom: 0;
        &.waf-listing {
            .first-list {
                @include card-count(1, var(--space-7), true);
                margin-bottom: var(--space-4)
            }
        }
        .waf-body {
            gap: var(--space-4);
            @include flex-config(flex, null, center);
        }
        .first-list {
            width: 65%;
            @include listing-card(horizontal);
            @include card-count(1, var(--space-4), true);
            .article {
                &-thumbnail {
                    width: 22rem;
                }
                &-meta {
                    margin-top: var(--space-6);
                    margin-bottom: 0;
                }
                &-content {
                    position: relative;
                    width: calc(100% - 22rem);
                    padding: var(--space-3);
                }
                &-title {
                    margin-top: var(--space-4);
                }
            }
            .meta-category {
                left: var(--space-3);
            }
        }
        .list-group-wrap {
            width: calc(35% - var(--space-4));
            padding: var(--space-5) 0;
            gap: var(--space-4);
            @include flex-config(flex, column, space-between);
        }
        .second-list {
            @include flex-config();
            .article {
                &-content {
                    padding-inline: var(--space-3);
                    padding-block: var(--space-6) var(--space-3);
                    background: var(--neutral-50);
                }
                // &-thumbnail {
                //     width: 30rem;
                // }
            }
            .article-meta {
                margin-top: auto;
                margin-bottom: 0;
            }
            .meta-category {
                top: 1rem;
                left: var(--space-3);
            }
        }
        .third-list {
            @include flex-config();
            @include listing-card(horizontal);
            .article {
                &-item {
                    background: var(--neutral-50);
                    padding: var(--space-3);
                }
                &-content {
                    padding: 0 0 0 var(--space-4);
                    position: relative;
                    width: calc(100% - 12rem);
                }
                &-thumbnail {
                    width: 12rem;
                }
                &-title {
                    margin-top: var(--space-5);
                }
                &-meta {
                    margin-top: var(--space-3);
                }
            }
            .meta-category {
                left: var(--space-4);
                top: 0;
            }
        }
    }
    .waf-series-ad {
        .event {
            &-title {
                padding-bottom: var(--space-4);
                .title {
                    font-size: 1.2rem;
                }
            }
            &-listing {
                gap: var(--space-5);
            }
            &-thumbnail {
                width: 40%;
            }
            &-content {
                width: calc(60% - var(--space-2));
            }
        }
        .head-wrap {
            padding-block: 0 var(--space-1);
        }
    }
}
@include mq(col-xxl) {
    .waf-series-listing {
        &.waf-listing {
            .first-list {
                @include card-count(1, var(--space-2), true);
            }
        }
        .first-list .article-thumbnail {
            width: 25rem;
        }
    }
}