@use "../config/" as *;
.container-fluid {
  .waf-showcase {
    &.widget-layout-04 {
      .layout-wrapper {
        width: 100%;
        max-width: 100%;
        @extend %pl-1;
      }
    }
  }
}
.waf-showcase {
  &.widget-layout-04 {
    height: var(--ticker-height);
    align-items: center;
    top: 0;
    z-index: var(--z-marquee);
    width: 100%;
    transition: 0.3s height, opacity;
    @extend %p-0;
    @extend %pure-white-900-bg;
    @extend %fixed;
    @extend %flex;
    .preview-swiper {
      width: 100%;
      .swiper-button-next,
      .swiper-button-prev {
        top: 0.6rem;
        @extend %d-none;
      }
      .swiper-button-next {
        right: var(--space-5);
      }
      .swiper-button-prev {
        right: var(--space-13);
        left: unset;
      }
      .article-title {
        @include truncate(
          var(--_line, 1),
          var(--_fontsize, 12),
          var(--_line-height, 20)
        );
        @extend %mt-0;
        @extend %font-12-pm;
        @extend %neutral-700;
      }
      .article-content {
        @extend %pl-8;
        @extend %pr-0;
      }
      .article-meta {
        @extend %d-none;
      }
    }
    &::before {
      @include icon(trending, 12);
      @extend %h-100;
      @extend %flex-n-c;
      @extend %px-2;
      @extend %primary-300;
    }
    .layout-wrapper {
      &::before {
        content: "TRENDING";
        white-space: nowrap;
        @extend %d-none;
        @extend %font-12-pb;
        @extend %secondary-800;
      }
      &:after {
        content: "";
        height: 50%;
        width: 0.1rem;
        left: 3rem;
        @extend %pos-y-center;
        @extend %neutral-600-bg;
      }
    }
    .article-list {
      overflow-x: auto;
      padding-right: 1rem;
      box-sizing: border-box;
    }
    .article-item {
      width: max-content;
      &::before {
        content: "";
        height: var(--space-2);
        width: var(--space-2);
        display: inline-block;
        left: var(--space-3);
        @extend %pos-y-center;
        @extend %circle-radius;
        @extend %primary-300-bg;
      }
    }
  }
}
@include mq(col-md) {
  .waf-showcase {
    &.widget-layout-04 {
      // top: var(--header-height);
      .layout-wrapper {
        .swiper-button-next,
        .swiper-button-prev {
          display: flex;
        }
        &::after {
          left: 3.2rem;
        }
      }
      .preview-swiper {
        width: calc(100% - 13rem);
        position: static;
        margin-left: 0;
        .article-list {
          display: flex;
          gap: 0;
        }
        .article-content {
          // padding-left: var(--space-5);
          // padding-right: var(--space-3);
          padding-inline: var(--space-5) var(--space-3);
        }
        .article-item::before {
          left: var(--space-1);
        }
        .article-list {
          overflow-x: unset;
          box-sizing: content-box;
        }
        .swiper-button-next,
        .swiper-button-prev {
          display: flex;
          transition: 300ms background-color;
          &:hover {
            background-color: var(--primary-500);
          }
        }
      }
    }
  }
}
@include mq(col-lg) {
  .waf-showcase {
    &.widget-layout-04 {
      top: var(--header-height);
      .layout-wrapper {
        margin-inline: auto;
        &::before {
          display: flex;
          align-items: center;
        }
        &::after {
          left: 10.5rem;
        }
      }
      .preview-swiper {
        width: calc(100% - 24rem);
      }
    }
  }
}
