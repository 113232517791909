@use "../config/" as *;

.waf-authorlisting {
    @include listing-card(vertical);
    @extend %py-10;
    .filter-section,.loadmore-wrap,.pagination-wrap {
        @extend %d-none;
    }
    .layout-wrapper {
        @extend %px-4;
    }
    .waf-head {
        --_border-color: var(--secondary-800);
        --_more-color: var(--secondary-800);
        @include drop-arrow();
        @extend %mb-5;
    }
    .article {
        &-list {
            @include card-count(2, var(--space-4));
            @extend %pt-12;
        }
        &-item {
            @extend %text-center;
            @extend %pure-white-900-bg;
            @extend %half-radius;
        }
        &-thumbnail {
            height: 10rem;
            width: 10rem;
            flex-shrink: 0;
            @extend %hidden;
            @extend %circle-radius;
            @extend %mx-auto;
            @extend %mt-12-neg;
        }
        &-content {
            @extend %px-4;
            @extend %py-4;
        }
        &-title {
            @extend %mb-3;
            @extend %font-14-pb;
            @extend %secondary-800;
            @extend %capitalize;
        }
        &-text {
            @extend %font-12-pm;
            @extend %neutral-400;
            @include truncate(3, 12);
            &:empty {
                @extend %d-none;
            }
        }
        &-description {
            @extend %mb-4;
            @extend %pb-0;
        }
        &-more {
            @extend %flex-c-n;
            @extend %mb-0;
            @extend %pb-0;
        }
        &-more-link {
            --_border-color: var(--secondary-800);
            --_more-color: var(--secondary-800);
            padding-block: 0;
            @include btn-animation;
            &:active {
                color: var(--secondary-800);
            }
        }
    }
}
@include mq(col-md) {
    .waf-authorlisting {
        .article-list {
            @include card-count(3, var(--space-4));
        }
    }
}
@include mq(col-lg) {
    .waf-authorlisting {
        @include listing-card(horizontal);
        .layout-wrapper {
            padding-inline: 0;
        }
        .article {
            &-list {
                padding-top: unset;
                padding-left: var(--space-12);
                @include card-count(4, var(--space-16));
            }
            &-wrap {
                display: flex;    
            }
            &-title {
                font-size: 1.6rem;
            }
            &-content {
                text-align: left;
                padding-left: 0;
                width: 100%;
            }
            &-more {
                justify-content: flex-start;
            }
            &-description{
                margin-bottom: var(--space-4);
            }
            &-text {
                @include truncate(2, 12)
            }
            &-thumbnail {
                width: 10rem;
                margin-left: var(--space-12-neg);
                margin-block: auto;
                margin-right: var(--space-4);
            }
        }
    }
}
