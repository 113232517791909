@use "../config" as *;

.waf-modal {
    width: var(--window-inner-width);
    height: var(--window-inner-height);
    z-index: var(--z-modal);
    overflow: auto;
    align-items: center;
    @extend %d-none;
    @extend %neutral-800-bg-8;
    @include position(0, 0, 0, 0, fixed);
    &.active {
        @extend %flex;
    }
	.modal {
        &-wrapper {
            @extend %half-radius;
            @extend %p-5;
            @extend %mx-auto;
            @extend %w-90;
            @extend %pure-white-900-bg;
        }
		&-head {
            width: 8.4rem;
            height: 8.4rem;
            @extend %p-4;
            @extend %mx-auto;
            @extend %neutral-50-bg;
            @extend %flex-c-c;
            @extend %circle-radius;
        }
		&-body {
			@extend %text-center;
			@extend %mb-5;
		}
		&-footer {
            .footer-wrapper {
                @extend %gap-2;
                @extend %flex-c-n;
            }
        }
	}
	.title {
        @extend %my-4;
        @extend %capitalize;
        @extend %font-20-pb;
        @extend %neutral-800;
    }
	.sub-title {
		@extend %my-4;
        @extend %font-14-pb;
        @extend %neutral-800;
	}
	.text {
		line-height: 2rem;
		@extend %font-14-pr;
        @extend %neutral-800;
	}
	.btn-modal {
        flex: 1;
        height: 4.8rem;
		border: .1rem solid;
        @extend %half-radius;
        .btn-text {
			@extend %secondary-800;
            @extend %uppercase;
            @extend %font-14-pb;
			@extend %flex-c-c;
			@extend %gap-2;
        }
		&.btn-outline {
			border-color: var(--secondary-800);
			.btn-text {
				&:after {
					font-weight: 500;
					width: 1.4rem;
					transition: .3s;
				}
				&::before {
					content: '';
					width: 1.4rem;
					height: .1rem;
					transition: .3s;
					@extend %secondary-800-bg;
				}
			}
			&:hover {
				border-color: var(--primary-300);
				@extend %primary-300-bg;
				.btn-text {
					&::after {
						@include icon(drop-arrow, 12);
					}	
					&::before {
						width: 0;
	                    overflow: hidden;
					}			
				}
			}
		}
		&.btn-fill {
			border-color: var(--primary-300);
			@extend %primary-300-bg;
			.btn-text {
				&:after {
					font-weight: 500;
					width: 1.4rem;
					@include icon(drop-arrow, 12);
				}
			}
		}
    }
}
@include mq(col-md) {
	.waf-modal {
		.modal-wrapper {
			width: 60%;
		}
	}
}
@include mq(col-md) {
	.waf-modal {
		.modal-wrapper {
			width: 30%;
		}
	}
}