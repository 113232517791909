@use "../config" as *;

.waf-breadcrumb {
    @extend %primary-300-bg;
    .breadcrumb {
        &-list {
            list-style: none;
            flex-wrap: wrap;
            @extend %p-4;
            @extend %flex-n-c;
        }
        &-item {
            display: inline;
            a {
                @extend %neutral-700;
                @extend %font-12-pm;
                span  {
                    @extend %neutral-700;
                    @extend %font-12-pm;
                }
            }
            &:last-child {
                line-height: 1.8rem;
                span {
                    @extend %neutral-800;
                    @extend %font-12-pb;
                }
            }
            &:not(:last-child) {
                @extend %relative;
                &::after {
                    content: '/';
                    font-size: 1.2rem;
                    @extend %px-2;
                    @extend %neutral-700;
                }
            }
        }
    }
}
@include mq(col-md) {
    .waf-breadcrumb {
        .breadcrumb-list {
            padding: 0;
            height: 4rem;
            align-items: center;
        }
    }
}