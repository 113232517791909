@use "../config" as *;
.filter-section {
    @extend %pure-white-900-bg;
    @extend %flex-sb-c;
    @extend %half-radius;
    @include position(var(--space-3), var(--space-4), null, null);
    .filter-wrap {
        @extend %d-none;
    }
    .btn-resetfilter {
        @extend %d-none;
    }
    .btn-more {
        height: 3.3rem;
        @extend %secondary-800-bg;
        @extend %relative;
        @extend %flex-c-c;
        @extend %gap-3;
        @extend %px-3;
        @extend %half-radius;
        &::before {
            @include icon(filter, 14);
            @extend %primary-300;
        }
        .btn-text {
            @extend %primary-300;
            @extend %uppercase;
            @extend %font-12-pm;
        }
    }
    .more-filters {
        z-index: var(--z-filter);
        transition: 300ms right;
        height: calc(100% - var(--header-height));
        @include position(0, -100%, null, null, fixed);
        @extend %w-100;
        @extend %pure-white-900-bg;
        .waf-select-box .select-list,
        .select-box-wrap {
            box-shadow: none;
            position: relative;
            padding: 0;
        }
        .select-box-wrap {
            border-top: .1rem solid clr(neutral-900, 3);
            margin-top: var(--space-3)
        }
        .head {
            height: 5.4rem;
            @extend %flex-sb-c;
            @extend %primary-300-bg;
            @extend %px-4;
            .title {
                @extend %uppercase;
                @extend %secondary-800;
                @extend %font-16-pb;
            }
        }
        .btn-cancel {
            @extend %flex-n-c;
            &::after {
                @extend %secondary-800;
                @include icon(close, 18);
            }
            .btn-text {
                @extend %font-0;
            }
        }
        .body {
            height: calc(100% - 12.2rem);
            @extend %overflow;
        }
        .footer {
            height: 6.6rem;
            box-shadow: 0 -.4rem 1rem 0 clr(neutral-900, 1);
            @extend %px-4;
            @extend %gap-3;
            @extend %flex-c-c;
            .btn {
                height: 3.4rem;
                flex: 1;
                border: .1rem solid;
                @extend %half-radius;
                @extend %flex-c-c;
                &-text {
                    @extend %font-12-pb;
                    @extend %uppercase;
                }
            }
            .btn-applyfilter {
                border-color: var(--primary-300);
                @extend %primary-300-bg;
                &::after {
                    @include icon(drop-arrow, 16);
                    @extend %pl-4;
                }
            }
            .btn-resetfilter {
                border-color: var(--secondary-800);
                background: transparent;
            }
        }
    }
    &.active {
        .more-filters {
            right: 0;
        }
    }
}
@include mq(col-md) {
    .filter-section {
        width: 100%;
        position: unset;
        background: var(--pure-white-900);
        padding: 0;
        flex-wrap: wrap;
        .sub-title {
            display: none;
        }
        .btn-more {
            position: absolute;
            right: var(--_filter-right, 0);
            top: var(--_filter-top, 6.8rem);
            width: var(--_filter-more-width, 16rem);
            background-color: var(--neutral-100);
            height: var(--_filter-more-height, 4rem);
            border-radius: 0 var(--half-radius) var(--half-radius) 0;
            &::before {
                color: var(--neutral-900);
            }
            .btn-text {
                color: var(--neutral-900);
            }
        }
        .filter-wrap {
            width: 100%;
            display: flex;
            gap: var(--space-4);
        }
        .waf-select-box {
            .selected-title {
                .title {
                    font-size: 1.2rem;
                    font-weight: 700;
                    padding-bottom: 0;
                }
            }
        }
        .more-filters {
            position: unset;
            padding-block: 0;
            padding-inline: var(--space-4);
            height: 0;
            overflow: hidden;
            transition: 300ms height, 300ms padding-block;
            border-top: .1rem solid var(--neutral-50);
            .head,
            .footer {
                display: none;
            }
            .body {
                display: flex;
                flex-wrap: nowrap;
                gap: var(--space-4);
                overflow: unset;
                height: 100%;
            }
            .select-box-wrap {
                position: absolute;
                top: 100%;
                left: 0;
                border-top: 0;
                background-color: var(--neutral-50);
                margin-top: 0;
            }
        }
        &.active {
            .more-filters {
                overflow: visible;
                height: 7.2rem;
                padding-block: var(--space-4);
            }
            .btn-more {
                &::before {
                    content: '\e813';
                    font-size: 1.2rem;
                }
                .btn-text {
                    font-size: 0;
                    &::before {
                        content: 'CLOSE';
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }
}