@use "../config" as *;
.waf-child-wrapper {
    @extend %mx-auto;
}
.waf-ad {
    width: max-content;
    z-index: var(--z-ads);
    @extend %mx-auto;
    @extend %relative;
    @extend %my-4;
    @extend %w-100;
    &-wrapper {
        z-index: var(--z-sticky-ad);
        margin-bottom: 0;
        background-color: transparent;
        // @include shimmer(null, null, 0);
        @extend %mx-auto;
        @extend %text-center;
        @extend %flex-c-c;
        > * {
            position: relative;
            z-index: var(--z-ads);
        }
    }
    &-sticky {
        position: fixed;
        bottom: var(--header-height);
        left: 0;
        margin-bottom: 0;
        z-index: var(--z-sticky-ad);
        transition: 300ms transform;
        width: 100%;
        @extend %neutral-50-bg;
        .waf-ad-wrapper {
            max-width: max-content;
        }
        @include mq(col-lg) {
            bottom: 0;
        }
        &:not(:has(iframe)) {
            transform: translateY(100%);
        }
    }
    &-leader &-wrapper {
        width: 32rem;
        height: 5rem;
    }
    &-square &-wrapper {
        width: 25rem;
        height: 25rem;
    }
    &-rectangle &-wrapper {
        width: 30rem;
        height: 10rem;
    }
    &-sticky &-wrapper {
        width: 32rem;
        height: 5rem;
    }
    &-content &-wrapper {
        width: 32rem;
        height: 5rem;
    }
    &-mrec &-wrapper {
        width: 30rem;
        height: 25rem;
    }
    &-halfpage &-wrapper {
        width: 30rem;
        height: 60rem;
    }
    &-skyscrapper &-wrapper {
        width: 16rem;
        height: 60rem;
    }
    &-multiplex &-wrapper {
        width: 30rem;
        height: 46rem;
    }
}
.site-common-wrap {
    margin-bottom: 5rem;
    &:not(:has(.waf-ad-sticky iframe)) {
        margin-bottom: 0;
    }
    @include mq(col-md) {
        margin-bottom: var(--sticky-ad-height);
    }
}
body:not(:has(.site-common-wrap .waf-ad-sticky iframe)) {
    --sticky-ad-height: 0;
}
.waf-detail {
    .waf-component {
        &.waf-ad {
            width: 100%;
            .waf-ad-wrapper > * {
                width: 100%;
                max-width: 72.8rem;
                @extend %flex-c-n;
            }
        }
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-ad {
        &-leader,
        &-rectangle,
        &-content {
            .waf-ad-wrapper {
                width: 46.8rem;
                height: 6rem;
            }
        }
        &-multiplex &-wrapper {
            width: 60rem;
            height: 22rem;
        }
        &-sticky &-wrapper {
            width: 97rem;
            height: 9rem;
        }
    }
}
@media screen and (min-width: $desktop-min-width) {
    .waf-ad {
        &-leader,
        &-rectangle,
        &-content {
            .waf-ad-wrapper {
                width: 72.8rem;
                height: 9rem;
            }
        }
        &-multiplex &-wrapper {
            width: 80rem;
            height: 42rem;
        }
    }
}