@use "../config" as *;
.waf-select-box {
    @extend %relative;
    @extend %p-4;
    &::before {
        content: "";
        height: 0.1rem;
        width: calc(100% - var(--space-8));
        @extend %neutral-50-bg;
        @include position(null, null, 0, var(--space-4));
    }
    &.active {
        .select-box-wrap {
            @extend %d-block;
        }
        .selected-title {
            &::after {
                transform: rotate(180deg);
            }
        }
    }
    .selected-title {
        cursor: pointer;
        @extend %pr-6;
        @extend %gap-2;
        @extend %relative;
        @extend %w-100;
        @extend %h-100;
        @extend %flex-column-c-n;
        .title {
            font-size: 1.2rem;
            font-weight: 700;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            min-height: auto;
            @extend %d-block;
            @extend %m-0;
            @extend %neutral-800;
        }
        .sub-title {
            @extend %neutral-800;
            @extend %uppercase;
            @extend %font-14-pm;
        }
        &::after {
            transition: 0.3s;
            @include position(null, var(--space-2));
            @include icon(p-arrow-down, 14);
        }
    }
    .select-box-wrap {
        z-index: var(--z-waf-select-box-wrap);
        top: 100%;
        transition: 0.3s;
        @extend %pure-white-900-bg;
        @extend %w-100;
        @extend %d-none;
        @extend %pos-tl;
        .dropdown-close {
            @extend %d-none;
        }
    }
    .select-list {
        @extend %pl-0;
        @include custom-scroll;
        .list-item {
            cursor: pointer;
            min-height: 3rem;
            @extend %flex-n-c;
            @extend %uppercase;
            @extend %neutral-700;
            @extend %px-4;
            @extend %py-2;
            @extend %font-12-pm;
            @extend %w-100;
            @extend %relative;
            @extend %d-block;
            @extend %text-left;
            &-text {
                @extend %neutral-700;
                @extend %font-14-pm;
                @extend %uppercase;
            }
            button {
                @extend %d-block;
                @extend %w-100;
                @extend %text-left;
                @extend %h-100;
            }
            &.active {
                font-weight: 700;
            }
        }
    }
}
@include mq(col-md) {
    .waf-select-box {
        flex: 1;
        background: var(--neutral-50);
        border-radius: var(--half-radius);
        height: 4rem;
        padding-block: 0;
        min-width: 15rem;
        &.active {
            border-radius: var(--half-radius) var(--half-radius) 0 0;
        }
        &::before {
            content: unset;
        }
        .selected-title {
            padding-right: var(--space-6);
        }
        .select-box-wrap {
            background: var(--neutral-50);
            border-radius: 0 0 var(--half-radius) var(--half-radius);
            max-height: 40rem;
            overflow-y: auto;
        }
    }
}