@use "../config/" as *;

.waf-cookies-popup {
    z-index: var(--z-cookies);
    @extend %d-none;
    @include position(0, null, null, 0, fixed);
    @extend %w-100;
    @extend %h-100;
    @extend %neutral-900-bg-8;
    &.active {
        @extend %d-block;
    }
    .cookie {
        &-wrap {
            max-width: 90%;
            @extend %flex-column;
            @include position(null, 0, var(--space-5), 0, fixed);
            @extend %primary-300-bg;
            @extend %mx-auto;
            @extend %pb-6;
            @extend %half-radius;
        }
        &-head {
            @extend %pt-1;
            @extend %pr-1;
            @extend %text-right;
            .btn-close {
                &::before {
                    @extend %secondary-800;
                    @include icon(close,12);
                }
                .btn-text {
                    @extend %font-0;
                }
            }
        }
        &-body {
            @extend %px-6;
            @extend %pb-5;
            .text {
                line-height: 2.2rem;
                @extend %neutral-800;
                @extend %font-16-pr;
                &-hightlight {
                    font-weight: 700;
                    @extend %neutral-800;
                }
            }
        }
        &-footer {
            @extend %px-6;
            .btn-submit {
                height: 3.4rem;
                border: .1rem solid var(--secondary-800);
                @extend %w-100;
                @extend %secondary-800;
                @extend %gap-2;
                @extend %flex-c-c;
                @extend %half-radius;
                @extend %uppercase;
                @extend %font-12-pb;
                &:after {
                    font-weight: 500;
                    width: 1.4rem;
                    @include icon(drop-arrow, 12);
                }
                &::before {
                    width: 1.4rem;
                    height: .1rem;
                    @extend %secondary-800-bg;
                }
                @include mq(col-lg) {
                    transition: .3s;
                    &::before {
                        content: '';
                        transition: .3s;
                    }
                    &::after {
                        width: 0;
                        overflow: hidden;
                        transition: .3s;
                    }
                    &:hover {
                        &::before {
                            width: 0;
                            overflow: hidden;
                        }
                        &::after {
                            width: 1.4rem;
                            @include icon(drop-arrow,12);
                        }
                    }
                }
            }
        }
    }
}
@include mq(col-lg) {
    .waf-cookies-popup {
        .cookie-wrap {
            max-width: 30%;
            right: var(--space-5);
            margin-inline: auto 0;
        }
    }
}