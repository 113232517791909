@use "../config/" as *;
.waf-almanack-listing {
    @extend %mt-0;
    @extend %py-10;
    @include bg(primary-300);
    .head-wrap {
        @include border(1, neutral-400, 5, bottom);
        @extend %pb-4;
    }
    .loadmore-wrap,
    .pagination-wrap {
        @extend %d-none;
    }
    .waf-child-wrapper {
        @extend %d-none;
    }
    .waf-head {
        @include drop-arrow();
        --_border-color: var(--secondary-800);
        --_more-color: var(--secondary-800);
        .head-tab {
            a {
                width: max-content;
            }
        }
    }
    .list-group-wrap {
        @extend %my-5;
    }
    .first-list {
        padding-bottom: 0;
        @include listing-card(vertical);
        @include card-count(1, var(--space-4), true);
        .article-title {
            @extend %mt-2;
        }
        .article-meta {
            margin-top: 1rem;
            .meta-category {
                @include color(secondary-600);
            }
        }
        .img-box {
            border-radius: 0;
        }
    }
    .second-list {
        @include listing-card(horizontal);
        @include card-count(1.01, var(--space-4));
        @extend %mr-4-neg;
        @extend %pr-4;
        .article {
            &-content {
                position: relative;
                padding-block: var(--space-3);
                @extend %px-4;
                @extend %py-3;
            }
            &-meta {
                @extend %mb-0;
                @extend %mt-5;
                .meta {
                    &-category {
                        top: var(--space-3);
                        @include color(secondary-600);
                    }
                    // &-author {
                    //     @extend %d-none;
                    // }
                }
            }
            &-title {
                @extend %mt-4;
            }
            &-wrap {
                height: 100%;
            }
            &-thumbnail {
                width: 18rem;
                .img-box {
                    height: 100%;
                }
            }
        }
        .img-box {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        .item-type-icon {
            @extend %d-none;
        }
    }
    .article {
        &-content {
            padding-inline: var(--space-4);
            padding-bottom: var(--space-4);
            @include bg(pure-white-900);
            .item-type-icon {
                @extend %d-none;
            }
        }
        &-meta {
            margin-top: auto;
            .meta-category {
                left: var(--space-4);
                @include color(secondary-600);
            }
            .meta-date {
                font-size: 1rem;
                @extend %neutral-500;
            }
            // .meta-author {
            //     @extend %d-none;
            // }
        }
        &-thumbnail {
            @extend %relative;
        }
    }
    .third-list {
        @extend %d-none;
    }
}
@include mq(col-md) {
    .waf-almanack-listing {
        margin-top: 0;
        .second-list {
            margin-right: 0;
            padding-right: 0;
            @include card-count(2, var(--space-4));
        }
        .layout-wrapper {
            padding-inline: 0;
        }
    }
}
@include mq(col-xl) {
    .waf-almanack-listing {
        margin-top: 0;
        padding-block: var(--space-15);
        .waf-body {
            gap: var(--space-4);
            margin-top: var(--space-6);
            @include flex-config(flex, null, center, stretch);
        }
        .first-list {
            padding-top: 0;
            width: 75%;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: var(--space-4);
            grid-row-gap: var(--space-4);
            & > .article-item:nth-child(1) {
                grid-area: 1 / 1 / 2 / 2;
            }
            & > .article-item:nth-child(2) {
                grid-area: 2 / 1 / 3 / 2;
            }
            & > .article-item:nth-child(3) {
                --_listing-title: 20;
                grid-area: 1 / 2 / 3 / 4;
                .article-description {
                    display: block;
                    margin-block: var(--space-2);
                    color: var(--neutral-800);
                    @include truncate(2, 14);
                }
                .article-wrap {
                    height: 100%;
                }
                .article-content {
                    padding-block: var(--space-10) var(--space-5);
                    flex-grow: 1;
                }
                .article-meta {
                    margin-block: var(--space-4) 0;
                    margin-top: auto;
                    .meta-category {
                        top: var(--space-5);
                    }
                }
            }
            .article {
                &-title {
                    margin: 0;
                }
                &-content {
                    padding-block: var(--space-6) var(--space-3);
                }
                &-meta {
                    margin-block: var(--space-2) 0;
                    .meta-category {
                        top: var(--space-2);
                    }
                }
                &-item {
                    width: 100%;
                }
            }
        }
        .list-group-wrap {
            width: calc(25% - var(--space-4));
            gap: var(--space-4);
            margin-block: 0;
            @include flex-config(flex);
        }
        .second-list {
            border-radius: var(--half-radius);
            padding: var(--space-4);
            margin-bottom: 0;
            @include listing-card(horizontal);
            @include card-count(1, var(--space-4), true);
            @include bg(pure-white-900);
            .article {
                &-item {
                    &:not(:last-child) {
                        @include border(1, neutral-400, 1, bottom);
                    }
                }
                &-content {
                    width: calc(100% - 10rem);
                    padding-block: 0;
                    padding-right: 0;
                    position: relative;
                }
                &-thumbnail {
                    width: 10rem;
                }
                &-meta {
                    width: 100%;
                    margin-top: var(--space-2);
                    .meta-category {
                        top: 0;
                    }
                }
            }
            .img-box {
                border-top-right-radius: var(--half-radius);
                border-bottom-right-radius: var(--half-radius);
            }
        }
        .article {
            &-meta {
                .meta-date {
                    // font-size: 1.2rem;
                }
            }
        }
    }
}
@include mq(col-xxl) {
    .waf-almanack-listing {
        .second-list {
            .article-wrap {
                padding-bottom: var(--space-2);
            }
        }
    }
}