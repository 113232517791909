@use "../config/" as *;
.waf-betting-listing {
    @extend %pure-white-900-bg;
    @extend %mx-4;
    @extend %half-radius;
    .head-wrap {
        @include border(1, neutral-400, 1, bottom);
        @extend %pb-4;
    }
    .first-list {
        padding-bottom: 0;
        @include listing-card(horizontal);
        @include card-count(1, var(--space-4), true);
        .article {
            &-title {
                line-height: 2.1rem;
                @extend %mt-3;
            }
            &-meta {
                margin-block: auto var(--space-2);
            }
            &-wrap {
                height: 100%;
            }
            &-content {
                padding-inline: var(--space-4);
                padding-block: var(--space-4) 0;
                position: relative;
            }
            &-thumbnail {
                .img-box {
                    height: 100%;
                }
            }
            &-item {
                @include border(1, neutral-50);
            }
        }
        .meta-category {
            left: var(--space-4);
            top: var(--space-3);
        }
    }
}
@include mq(col-md) {
    .waf-betting-listing {
        .first-list {
            @include card-count(1, var(--space-4), true);
            .article {
                &-thumbnail {
                    width: 22rem;
                }
                &-content {
                    width: calc(100% - 22rem);
                }
            }
        }
        .second-list,
        .third-list {
            // margin-top: var(--space-6);
            @include listing-card(horizontal);
            @include card-count(1, var(--space-4), true);
            .article {
                &-thumbnail {
                    width: 22rem;
                    .img-box {
                        height: 100%;
                    }
                }
                &-content {
                    width: calc(100% - 22rem);
                    padding-inline: 16px;
                    padding-block: 0;
                    position: relative;
                }
                &-title {
                    margin-top: var(--space-6);
                    line-height: 2.1rem;
                }
            }
            .meta-category {
                left: var(--space-4);
                top: var(--space-3);
            }
        }
        .second-list {
            height: 100%;
            .article-wrap {
                height: 100%;
            }
            .article-content {
                height: 100%;
            }
            .article-meta {
                height: max-content;
                margin-top: auto;
            }
        }
    }
    .betting-site-rhs {
        margin-top: var(--space-4);
    }
}
@include mq(col-lg) {
    .waf-betting-listing {
        margin: 0;
        &.waf-listing {
            .first-list {
                @include card-count(1, var(--space-5), true);
            }
            .article-meta {
                margin-top: auto;
            }
        }
        .list-group-wrap {
            padding-top: var(--space-5);
            display: flex;
        }
        .waf-body {
            gap: var(--space-4);
            @include flex-config(flex, null, center, stretch);
        }
        .first-list {
            width: 65%;
            .article {
                &-thumbnail {
                    width: 21rem;
                }
                &-content {
                    width: calc(100% - 21rem);
                }
            }
        }
        .list-group-wrap {
            width: 35%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: var(--space-3);
        }
        .second-list {
            margin-top: 0;
            width: 100%;
            @include listing-card(vertical);
            .article {
                &-item {
                    background: var(--neutral-50);
                }
                &-content {
                    padding: 0 var(--space-4) var(--space-3);
                }
                &-title {
                    margin-top: var(--space-6);
                }
                &-meta {
                    margin-top: auto;
                }
            }
            .meta-category {
                top: var(--space-3);
                left: var(--space-4);
            }
        }
        .third-list {
            width: 100%;
            padding-left: 0;
            .article {
                &-item {
                    padding: var(--space-4);
                    background: var(--neutral-50);
                }
                &-thumbnail {
                    width: 11rem;
                }
                &-content {
                    width: calc(100% - 11rem);
                    padding-right: 0;
                }
                &-title {
                    margin-top: var(--space-3);
                }
            }
            .meta-category {
                top: 0;
            }
        }
    }
    .betting-site-rhs {
        margin-top: 0;
    }
}
@include mq(col-xl) {
    .waf-betting-listing {
        &.waf-listing {
            .first-list {
                @include card-count(1, var(--space-7), true);
            }
        }
    }
}