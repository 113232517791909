// shimmer animation starts
@keyframes shimmerAnimation {
  100% {
    transform: translateX(100%);
  }
}
// shimmer animation starts
@keyframes button-loading-spinner {
  from {
      transform: rotate(0turn);
  }
  to {
      transform: rotate(1turn);
  }
}