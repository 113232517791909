@use "../config/" as *;
.waf-statslisting {
    @extend %py-5;
    .waf-head {
        width: max-content;
        @extend %px-4;
        @extend %pb-5;
    }
    .tabs,
    .more-btn,
    .swiper-button-next,
    .swiper-button-prev {
        @extend %d-none;
    }
    .card {
        &-list {
            overflow-x: auto;
            box-sizing: border-box;
            @extend %px-4;
            @extend %gap-4;
        }
        &-item {
            width: 85%;
        }
        &-head {
            height: 4rem;
            border-radius: var(--half-radius) var(--half-radius) 0 0;
            @extend %px-4;
            @extend %flex-n-c;
            @extend %primary-300-bg;
            .title {
                @extend %secondary-800;
                // @extend %capitalize;
                @extend %font-14-pb;
            }
        }
    }
    .team-item {
        height: 5rem;
        border-bottom: .1rem solid clr(neutral-900, 1);
        @extend %pure-white-900-bg;
        @extend %px-4;
        @extend %flex-n-c;
        .item-wrap {
            @extend %h-100;
            @extend %w-100;
            @extend %flex-n-c;
        }
    }
    .rank {
        flex-shrink: 0;
        width: 2.5rem;
        @extend %secondary-800;
        @extend %font-12-pb;
    }
    .logo {
        @extend %pr-2;
        img {
            border: .1rem solid var(--neutral-50);
            width: 3.8rem;
            height: 2.2rem;
        }
    }
    .name {
        flex: 1;
        line-height: 1rem;
    }
    .fname,
    .lname {
        @extend %capitalize;
        @extend %secondary-800;
    }
    .fname {
        @extend %font-12-pm;
    }
    .lname {
        @extend %font-12-pb;
    }
    .total-points {
        flex-shrink: 0;
        width: 5rem;
        @extend %text-right;
        .points {
            @extend %secondary-800;
            @extend %font-16-pb;
        }
        .label {
            @extend %d-none;
        }
    }
    .featured {
        height: 8rem;
        background: var(--primary-50);
        .rank {
            width: 1.9rem;
        }
        .name {
            gap: .2rem;
            @extend %flex-column;
        }
        .fname,
        .lname {
            font-size: 1.4rem;
        }
        .logo {
            img {
                width: 4.4rem;
                height: 2.5rem;
            }
        }
    }
}
@include mq(col-md) {
    .waf-statslisting {
        padding-block: var(--space-8);
        .waf-head {
            padding-inline: 0;
        }
        .card {
            &-list {
                padding-inline: 0;
                box-sizing: content-box;
                overflow-x: unset;
                gap: 0;
            }
            &-item {
                width: 40%;
                margin-right: var(--space-4);
            }
        }
        .swiper {
            position: static;
            margin-inline: calc(var(--container-white-space)* -1);
            padding-inline: calc(var(--container-white-space));
        }
        .swiper-button {
            display: flex;
            &-prev {
                left: auto;
                right: calc(var(--container-white-space) + 3.3rem);
            }
            &-next {
                right: var(--container-white-space);
            }
            &-prev,
            &-next {
                display: flex;
                top: var(--space-5);
                &.swiper-button-disabled {
                    background: var(--pure-white-900);
                    opacity: .8;
                }
            }
        }
    }
}
@include mq(col-lg) {
    .waf-statslisting {
        .card-item {
            width: 23.5%;
        }
    }
}